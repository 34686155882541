*{
  letter-spacing:0.025em !important;
}

.table-xm tbody td, 
.table-xm thead th{
  padding: 1em 0.4em 1em 0.4em ;
}

.table-hover tbody tr:hover {
  color: #12263f;
  background-color: #ebf5ff!important;
}

.align-right{
  text-align: right !important;
}

.input-range__label{
  font-size: 1em;
  color: black;

}

.input-range__label--max{
  display: none!important;
}

.input-range__label--min{
  display: none!important;
}

.input-range__label-container{
  color: #2c7be5;
  font-weight: 400;
}

.input-range__label-container{
  box-shadow: 0 .125rem .25rem rgba(18,38,63,.075) !important;
  cursor:auto!important
}

.DatePicker {
  width: 100%;
}


div.scrollable-table-wrapper {
  overflow: auto;
}


div.scrollable-table-wrapper thead tr th {
  position: sticky;
  top: 0;
}



table td.highlighted {
  background-color:#999;
}


.cell-selected{
  background-color: #85aada;
  border: 0px solid #12263F!important;
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}

.cell-being-selected{
  background-color: #eff5fd;
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}

.cell-enabled{
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}

.thead{
  background-color: #f9fbfd;
    text-transform: uppercase;
    font-size: .625rem;
    font-weight: 600;
    letter-spacing: .08em;
    color: #95aac9;
    border-bottom-width: 1px;
    border-bottom: 2px solid #edf2f9;
}

.tr-pad{
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
  border: 1px solid #D5E5FA;
}

.pdf-resume{
  height: 30px;
  /* width: 20px; */
  object-fit: cover;
  cursor: pointer;
}

.mt-48{
  margin-top: 3rem !important;
}

.mb-40{
  margin-bottom: 40px;
}
.mt-50{
  margin-top: 50px
}
.ml-30{
  margin-left: 30px;
}
.height_70{
  min-height: 70.4px;
}

.horizontal-center{
  margin-left:50%;
  transform: translateX(-50%);
}

#pageBtn{
  border-top-width: 1px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.font-size-100{
  font-size: 100px !important;
}

.select-wrapper {
  width: 18.125rem;
  text-align: left;
  font-size: 13px;
}

.text-common{
  font-family: 'Inter';
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  font-weight: 650;
  border-radius: 10px;
  padding: 4px 7px ;
}
.text-approved{
  width: 87px;
  color: #21924E;
  background: #DDF4DD;
}
.text-changesRequested{
  width:135px;
  color: #D30000;
  background: #FFE0E0;
}
.text-empty{
  width: 80px;
  color:#965E00;
  background: #FFECCC;
}
.text-not-approved{
  width: 110px;
  color: #8A8A8A;
  background: #e7e6e6;
}
.text-draft{
  width: 110px;
  color: #ceaf03;
  background: #fdf6be;
}
.sent-feedback{
  border: none;
  background: #E7E7E7;
  border-radius: 12px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #3D3D3D;
  padding: 5px 30px;
  text-align: center;
  pointer-events: none;
  width: fit-content;
}
.belowText{
  color: #6E6893;
  font-size:13px;
}
.dflex{
  display: flex;
  gap: 5px;
}
.submitted{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  margin-top: 5px;
  font-size: 13px;
  line-height: 15px;
  color: #3C9B6A;
}
.submitReview{
  border-radius: 10px;
  padding: 5px 15px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  text-transform: lowercase;
}
.preview-not-available{
  opacity: 0.5;
  cursor: not-allowed;
}