.header-parent{
    width: 100%;  
    background: #123FBC;
    box-shadow: -1px -1px 30px -3px rgba(0, 0, 0, 0.06);
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    align-items: center;

    .disable-btn {
           opacity: 0.5;
           pointer-events: none;
    }
        .header-text {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            letter-spacing: 0.095em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        .image {
            object-fit: cover;
        }
        .download-btn {
            width: fit-content;
            height: fit-content;
            color: #123FBC;
            background: white;
            font-family: 'Poppins';
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
        }
        .download-btn:hover {
            color: #123FBC;
            background: white;
        }
}