.stepper-main-bg {
  background: #FFFFFF;
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.step-wrapper {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0px 15px;
  padding: 4px;
  text-align: center;
  color: #FFFFFF;
}

.step-number-error {
  background-color: #D94C4C;
}

.step-number-success {
  background-color: #0D3270;
}

.step-number-current {
  border: double;
  width: 34px;
  height: 34px;
  background-color: #0D3270;
}

.step-number-next {
  border: 1px solid #7B9ACD;
  background-color: #FFFFFF;
  color: #7B9ACD;
}

.step-description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.025em;
  color: #0D3270;
}

.step-description-error {
  color: #D94C4C;
}

.step-description-next {
  color: #7B9ACD;
}

.divider-line {
  margin-left: 10px;
  border: 1px solid #0D3270;
}

.divider-line-error {
  border: 1px solid #D94C4C;
}

.divider-line-next {
  border: 1px solid #7B9ACD;
}

.display-none {
  display: none;
}

.width-25 {
  width: 25%;
}

.width-15 {
  width: 15%;
}

.pointer{
  cursor: pointer;
}