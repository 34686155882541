.side-component-parent{
    height: 386px;
    background: #FFFFFF;
    box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
    border-radius: 22px;
    padding: 15px;

    .logo-section{
        max-width: 224px;
        width: fit-content;
        max-height: 85px;
        margin: auto;
        object-fit: contain;
        border-radius: 12px;
        // background-color: grey;
    }


    .details-section{
        background: #F3F5FA;
        border-radius: 14px;
        width: 224px;
        min-height: 252px;
        margin-top: 10px;
        padding-left: 10px;
        padding-right:10px;
        padding-top: 20px;

        .mt-20{
            margin-top: 20px;
        }

          .heading{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            color: #0F2359;
          }

          .details{  
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 110%;
            letter-spacing: 0.025em;
            color: #52659B;
            margin-top: 2px;
          }
    }
}

.side-component-image-section{
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 4px 16px;
  border-radius: 16px;
  padding-bottom: 24px;
  .heading{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.025em;
    color: #52659B;
    margin-bottom: 5px;
    padding: 5px 10px;
  }
  .camera-shot {
    width: 260px;
    height: 200px;
    border-radius: 12px;
  }
}