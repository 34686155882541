.UserComp{
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
        height: 40px;
    }
    .fl-row{
        display: flex;
    }
    .fl-col{
        display: flex;
        flex-direction: column;
    }
    .txt-align-right{
        text-align: right;
    }
    .mr-10{
        margin-right: 10px;
    }
    .mr-30{
        margin-right: 30px;
    }
    .profile-cont-auto{
        margin: auto;
        background-color: white;
        padding: 5px 0 30px 0;

        @media (min-width: 768px){
            padding: 5px 32px 30px 32px;
        }
    }

    .headerComp{
        .heading{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
        .sub-heading{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
    }
    .p-btm{
        padding-bottom: 21px;
    }
    .pr-60{
        padding-right: 60px;
    }
    .p-l-r{
        padding-left: 34px;
        padding-right: 49px;
    }
    .pos-rel{
        position: relative;
    }
    .date-pickeroWN{
        width: 149px;
        height: 30px;
        border: 1px solid #9A9A9A;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 14px;
    }
    .dateIcon{
        position: absolute;
        top: 2px;
        right: 5px;
    }
    .bttn{
        width: 131px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #123FBC;
        box-sizing: border-box;
        border-radius: 4px;
        color: #123FBC;
    }

    .sideBorder{
        height:30px;
        border-right: 2px solid #C9C9C9;

    }

    .nav-pad{
        padding: 12px 35px;
    }
    .w-33{
        width: 33%;
    }
    .inline-block{
        display: inline-block;
    }
    .drag-drop{
        background: #D5E5FA !important;
        border: 2px dashed #B6B7BF !important;
        box-sizing: border-box;
        border-radius: 4px !important;
        color: #3D3D3D !important;
        text-align: left!important;
    }
    
    .bold-600{
        font-weight: 600;
    }
    .nav-activeTab{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.025em;
        color: #123FBC !important;
        border-bottom: 3px solid #123FBC !important;
    }
    .line-border{
        position: absolute;
        bottom: 0;
        border-top: 1px solid #A5B2D7;
        width: 100%;
    }
    .btn-save{
        width: 186px;
        height: 36px;
        background: #123FBC;
        border: 0;
        border-radius: 2px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        margin-top: 20px;
    }
    .btn-upload{
        width: 81px;
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #123FBC;
        box-sizing: border-box;
        border-radius: 2px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #123FBC;
    }


    .custom-control-input:checked~.curentWork::before{
            background-color: #123FBC !important;
    }

    .curentWork{
        &::before{
            color: #fff;
            border-color: #123FBC !important;
            border-radius: 2px !important;
        }
    }
    .del-icon{
       color: #123FBC;
    }
    .update-btn{
        width: 192px;
        height: 36px;
        background: #123FBC;
        border-radius: 2px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        margin-bottom: 5px;
        border: 0;

    }
    .tittle{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.025em;
        text-transform: capitalize;
        color: #3D3D3D;
    }
    .overall-content{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        letter-spacing: 0.025em;
        color: #3D3D3D
    }
    .card-disabled{
        pointer-events: none;
        opacity: 0.3;
    }

    
}
