.modal-title {
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 126%;
    text-align: center !important;
    letter-spacing: 0.025em !important;
    color: #3d3d3d !important;
}
.modal-content {
    min-height: 300px;
}
.modal-action-buttons-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .modal-btn {
        border: 1px solid #123fbc !important;
        width: 45%;
        height: 36px;
        margin: 0 !important;
    }
}
.time-slot-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0 16px 0;

    .slot-text {
        font-family: Poppins !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 126%;
        text-align: left;
        letter-spacing: 0.025em !important;
        color: #3d3d3d !important;
        margin-bottom: unset;
    }
}

.error-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 126%;
    text-align: center;
    letter-spacing: 0.025em;
    color: red;
    margin: 0;
}

.btn .MuiButton-label {
    text-transform: capitalize;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
}

.btn:hover {
    color: white;
}
.btnPrimary:hover {
    color: #123fbc;
}

.modal-btn--disabled {
    background-color: #c6c6c6 !important;
    color: #ffffff !important;
}
.modal-btn--enabled {
    background-color: #123fbc !important;
    color: #ffffff !important;
}
