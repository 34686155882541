.empty-table-wrapper{
    height: 300px;
    .empty-box{

    }
    .empty-box-text{

    }
}

.requests-container {
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
}