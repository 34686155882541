.sidecomponent_parent {
  width: 257px;
  padding:20px;
  background: #ffffff;
  border-radius: 22px;
  box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .client-logo{
    width: 220px;
    margin:0px 10px 10px 10px;
    height: 80px;
    border-radius: 12px;
    object-fit: contain;
  }
  .sidecomponent_body {
    width: 224px;
   background: #f3f5fa;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .body_content {
      display: flex;
      flex-direction: column;
    }

    .sidecomp_headings {
      width: 126px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: #0f2359;
    }

    .sidecomp_name {
      width: 141px;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      letter-spacing: 0.025em;
      color: #52659b;
      margin-bottom: 10px;
    }

    .sidecomp_role {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      letter-spacing: 0.025em;
      color: #52659b;
    }
    .sidecomp_position{
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      letter-spacing: 0.025em;
      color: #52659b;
      margin-bottom: 10px;
    }

    .sidecomp_dsa {
      margin-bottom: 10px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0.025em;
      color: #52659b;
    }

    .sidecomp_time {
      width: 230px;
      // height: 15px;
      margin-top: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;

      letter-spacing: 0.025em;

      color: #52659b;
    }

    .sidecomp_date {
      width: 230px;
      // height: 15px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.025em;

      color: #7e94d0;
      margin-top: 8px;
    }
  }
}
