.request-card-wrapper {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    gap: 8px;
    background-color: #ffffff;

    &:active {
        background-color: #ececec;
    }

    .info-label{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.025em;
    }

    .info-value { // For single info value
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.025em;
    }

    .info-value + .info-value { // For multiple info values
        margin-top: 8px;
    }
}

// For shading odd numbered cards
.request-card-wrapper.card-odd {
    background-color: #f5f5f9;

    &:active {
        background-color: #dbdada;
    }
}