.no-data-text{
    font-family: Poppins !important; 
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 117.4% !important;
    text-align: center !important;
    letter-spacing: 0.025em !important;
    color: #9A9A9A;
    margin-top: 20px !important;
}
.table-title{
    color: #3D3D3D;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 117.4%;
    letter-spacing: 0.025em;
    text-transform: capitalize;
}
.see-all-link{
    margin-right: 12px;    
}
.see-all-link a{
    color:#3D3D3D !important;
}
.table-row-odd{
    background-color: #F5F5F9;
}
.requests-table{
    max-height:450px;
}
.table-value{
    height:30px;
}
thead{
    height:50px;
}
.td-value{
    font-family: Poppins !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 18px;
    color: #3D3D3D;
}
.td-value-sub{
    font-family: Poppins !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important; 
    line-height: 18px;
    color: #858585;
}
.date-row td{ 
    border:none;
    padding: 0 !important;
}
.accordian-icon{
    top:20px;
    right:5px;
    cursor: pointer;
    height:10px;
    width: 14px;
}
.guide-img{
    width:50px;
    height:70px;
}
.guide-link{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 126%;
    text-decoration-line: underline;
    color: #123FBC;
    cursor: pointer;
    margin-top: 10px;
}
.check-text{
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 500 !important; 
    font-size: 12px !important;
    line-height: 126%;
    color: #3D3D3D;
}