.forgot-password-wrapper{
    padding: 20px 30px;
    position: relative;
    height: 100vh;
    width: 100%;
    .back-arrow{
        position: absolute;
        top: 20px;
        left: 20px;
    }
    .banner-img{
        width: 15.375rem;
        height: 11.438rem;
        object-fit: contain;
    }
    .banner-img-full{
        width: 80%;
        height: 80%;
        object-fit: contain;
    }
    .width-20{
        width: 20%;
    }
    .width-50{
        width: 50%;
    }

    .full-width{
        width: 100%;
    }
    .sign-in-text{
        font-family: Source Sans Pro; 
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
        text-align: center;
        margin-bottom: 8px;
        margin-top: 12px;
    }
    .access-dashboard-text{
        font-family: Source Sans Pro;
        font-size: 12px;
        line-height: 15px;
        color: #6D84C6;
        text-align: center;
        margin-bottom: 27px;
    }
    .input-full{
        width: 25rem;
    }
    .input-mobile{
        width: 17.5rem;
     }
    .mb-24{
        margin-bottom: 24px;
    }
    .sign-in-btn{
        background: #123FBC;
        border-radius: 4px;
        max-width: 280px;
        width: 280px;
    }
    .iv-logo{
        height: 30px;
        width: 30px;
        object-fit: contain;
    }
    .company-logo-text{
        font-family: Poppins;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.025em;
        text-transform: capitalize;
        color: #3D3D3D;
        margin-left: 9px;
    }
    .bottom-logo{
        position: absolute;
        bottom: 10px;
        right: 0;
        left: 0;
    }
}