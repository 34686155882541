.feedback_nav {
    width: 100%;
    height: 50px;
    background-color: black;
    font-family: Poppins;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: white;
    padding-top: 12px
}

.btnBox{
    margin-top: 1%;
    .submitButton {
        border-radius: 10px;
        float: right;
        width: 187px;
        font-weight: 600;
        font-size: 16px;

    }

    .saveBtn{
       background-color: #3874CB;
       height: 42px;
       width: 190px;
       border-radius: 8px;
       color: #FFFFFF;
       float: right;
    }
}

.feedback_body {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    margin-top: 4%;

    .tablebox {
        margin-top: 20px;
        margin-top: 20px;
        box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
        border-radius: 20px;
        padding: 15px;
        background-color: #ffff;
    }

    .table_heading {
        font-weight: 600;
        font-size: 15px;
        border-bottom: 0px;
    }

    .text_box {
        margin-left: 15px;
        padding: 10px;

        .text-area-comment {
            display: flex;
        }

        .red {
           border-color: red;
        }
        .field-button {

            display: flex;
        }

        .delete-bg {
            font-family: 'Poppins';
            border: 1px solid #3d3d3d;
            background: #ffffff;
            width: 35px;
            height: 35px;
            align-self: center;
            margin-left: 10px;


        }

        .add-more {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 19px;
            letter-spacing: 0.025em;
            color: #ffffff;
            background: #3d3d3d;
            width: 35px;
            height: 35px;
            align-self: center;
            margin-left: 10px;

        }
    }

    .text_text {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        color: black;
    }

    .text_area {
        margin-left: 10px;
        padding-left: 10px;
        width: 900px;
        margin-bottom: 10px;
        border: 1px solid #cecdcd;
    }

    .mb-10 {
        margin-bottom: 10px;
    }
    .assessment_box {
        border-bottom: 1px solid #ECECEC;

        .ass_qus {
            width: 680px;
            border-bottom: 0px;
            font-size: 15px;
        }

        .ass_ans {
            font-size: 15px;
            padding: 6px;
            border-bottom: 0px;
        }
    }

    .summary_box {
        margin-top: 10px;

        .summ_first {
            width: 500px;
            border-bottom: 0px;
            font-size: 15px;
        }

        .summ_second {
            font-size: 15px;
            padding: 6px;
            border-bottom: 0px;
        }
    }

    .rating_box {
        margin-left: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        padding: 15px 15px 25px 15px;
        border-bottom: 3px solid #cecdcd;

        .emojiIcon {
            height: 25px;
            width: 25px;
            margin-top: 8px;
        }

        .emojiIcon2 {
            height: 25px;
            width: 26px;
            margin-top: 6px;
        }

        .ratings {
            padding-left: 30px;
            width: 715px;
            height: 42px;
            background: #F5F8FF;
            border-radius: 46px;
        }

        .rating_div {
            display: flex;
            gap: 20px;
            padding-left: 10px;

            .moveleft {
                margin-left: 48px;
            }
        }
    }

    .performance_box {
        margin-top: 10px;
        padding: 15px 0px 25px 15px;
        border-bottom: 3px solid #cecdcd;

        .performance1 {
            background-color: #F5F8FF;
            height: 45px;
            margin-top: 20px;
            margin-left: -10px;
            padding: 10px 0px 15px 15px;
            border-radius: 6px;
            display: flex;
            gap: 223px;

            .overall {
                margin-left: 35px;
            }

            .semibold {
                font-weight: 600;
            }
        }

        .performance_table {
            margin-left: 80px;
            border-radius: 6px;
            color: black;
        }

        .performance_cell {
            font-weight: 600;
            width: 400px;
        }

        .performance2 {
            padding: 10px 0px 15px 15px;
            margin-left: -10px;
        }

        .performance_box2 {
            display: flex;
            padding: 10px 0px 2px 15px;
        }

        .chooseRating {
            color: grey;
            display: flex;
            font-size: 15px;

            .choose1 {
                height: 30px;
                margin-top: -10px;
            }

            .choose2 {
                height: 30px;
                margin-top: -8px;
            }
        }
    }

    .blues {
        background-color: #F5F8FF;
        color: black;
    }

    .skillrow {
        display: flex;
        padding: 10px 5px 2px 5px;
    }

    .greens {
        color: green;
        font-weight: 600;
    }

    .star {
        color: red;
    }

    .warning {
        color: red;
        font-size: 15px;
        margin-left: 15px;
    }

    .warning-box {
        margin-top: 10px;
        margin-bottom: 200px;
        text-align: right;
        float: right;
        background-color: #ffffff;
    }

    .last_warning {
        color: red;
        text-align: right;
        float: right;
    }

    .bold {
        font-weight: 600;
    }

    .lastbutton {

        margin-top: 20px;

        .submitButton {
            border-radius: 10px;
            float: right;
            width: 187px;
            font-weight: 600;
            font-size: 16px;

        }

        .saveBtn{
           background-color: #3874CB;
           height: 42px;
           width: 190px;
           border-radius: 8px;
           color: #FFFFFF;
           float: right;
        }


    }
}

.modal_box {
    height: 210px;
    width: 580px;
    color: black;
    background: white;
    box-shadow: 2px 3px 14px -5px rgba(0, 0, 0, 0.17);
    border-radius: 14px;
    margin-left: 35%;
    padding: 10px 17px 10px 20px;
    font-size: 15px;

    .modal_head {
        font-weight: 700;
        color: black;
        font-size: 18px;
        height: 45px;
        margin-top: 10px;
        border-bottom: 1px solid rgb(177, 177, 177);
        margin-bottom: 15px;

        .crossbutton {
            margin-left: 320px;
        }

        .crossBtnAlignment{
            margin-left: 375px;
            margin-top: -55px;
        }
    }

    .modal_footer{
        display: flex;
        .submitButton {
            border-radius: 10px;
            float: right;
            font-weight: 600;
            font-size: 16px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 200px;
            min-width: 145px;
          
            color: #fff;
            background-color: #1976d2;
            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
            padding: 5px 15px;
            border: 1px solid rgba(25, 118, 210, 0.5);
            cursor: pointer;
            height: 39px;
        }

        .cancelButton {
            width: 180px;
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 200px;
            border-radius: 10px;
            margin-left: 240px;
        }

         .editClientCancelBtn{
             border: 1px solid #3D3D3D;
             border-radius: 4px;
             color: #3D3D3D;
             width: 110px;
             height: 32px;
             margin-left: 33%;
         }

         .editClientSubmitBtn{
                background-color: #3E3D3D;
                border-radius: 4px;
                color: #FFFFFF;
                font-weight: 500;
                width: 110px;
                height: 32px;

         }
    }

}

.editModalBox{
    width: 460px;
    height: 233px;
}