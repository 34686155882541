.relax-text{
    font-family: Poppins;
    font-weight: bold;
    font-size: 45px;
    line-height: 91%;
    text-align: left;
    letter-spacing: 0.055em;
    color: #F15A24;
    margin-bottom: 5px;
}
.get-back-text{
    font-family: Poppins;
    font-weight: bold;
    font-size: 26px;
    line-height: 91%;
    text-align: left;
    letter-spacing: 0.055em;
    color: #123FBC;
    margin-bottom: 57px;
}
.reset-pass-img{
    width: 455px;
    object-fit: contain;
    align-self: flex-end;
}

.main-container-height{
    min-height: 100vh;
}
