.prescreeningReport-parent{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    .prescreening-report-body{
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 16px;
        .side-component{
            margin-left: 40px;
        }

        .report-section{
            margin-left: 50px;
        }

    }
}