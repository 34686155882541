.slot-modal-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 30px 20px;
    background: #FFFFFF;
    // border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 1px 18px -3px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 9999999;
    outline: none;

    .heading-text{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 13px;
        line-height: 117.4%;
        text-align: center;
        letter-spacing: 0.025em;
        color: #3D3D3D;
        margin: 0 10px;
        margin-bottom: 14px;
    }
    .check-guideline{
        margin-top: 20px;
        margin-bottom: 12px;
    }
    .time-label{
        font-family: Source Sans light;
        font-size: 13px;
        line-height: 117.4%;
        text-align: center;
        letter-spacing: 0.025em;
        color: #474747;
    }
    .date-time{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 13px;
        line-height: 117.4%;
        text-align: center;
        letter-spacing: 0.075em;
        color: #3D3D3D;
        margin-bottom: 29px;
        margin-top: 7px;
    }
    .guide-img{
        height: 16px;
        width: 16px;
        object-fit: contain;
    }
    .btn-disable{
        background-color: #C6C6C6;
        color:#FFFFFF;
    }
    .btn--enable{
        background-color: #123FBC;
        color:#FFFFFF;
    }
    .btn{
        width: 48%;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .outlined{
        border: 1px solid #123FBC;
    }
    .bg-white{
        background-color: white;
    }
    .bg-grey{
        background: #123FBC;
    }
    .bg-red{
        background: #E80512;
    }
    .clr-white{
        color: white;
    }
    .clr-blue{
        color: #123FBC;
    }
}