.modal-error {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 23px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #B22B2B;
}

.modal-details {
  margin-top: 20px;
}

.modal-heading {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #CDCDCD;
}

.modal-feedback-summary {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #5F5F5F;
  margin-bottom: 10px;
}

.modal-highlights {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.025em;
  color: #3D3D3D;
  margin-bottom: 10px;
}

.modal-five-points {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.025em;
  color: #B22B2B;
  margin-bottom: 10px;
}

.modal-proceed-recomand {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: #947C7C;
}

.modal-recomend-box {
  margin-top: 20px;
}

.modal-ok-button {
  width: 249px;
  height: 54px;
  background: #3D3D3D;
  outline: none;
  border: none;
  border-radius: 5px;
  margin-top: 40px;
  float: right;
  cursor: pointer;
  font-weight: bold;
  color: white;
  font-size: 1.2rem;
}