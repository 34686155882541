.accordian-wrapper{

    .heading-text{
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.025em;
    }
    .color-black{
        color: #3D3D3D;
    }
    .color-grey{
        color: #a5a5a5;
    }
    .arrow-text{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.025em;
        color: #123FBC;
        margin: 0 5px 0 15px;
    }

}