.seperator-wrapper{
    margin: 20px 3px;
    .text{
        font-family: Source Sans Pro;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #555555;
        
    }
    .text-with-underline{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 13px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
        text-decoration-line: underline;
        color: #123FBC;
    }
}