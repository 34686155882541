.question-container {
    .theory-question-header {

        .theory-question-header-text {
            display: flex;
            gap: 20px;

            .theory-question-title {
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.1em;
                text-align: left;
                color: #0d3270;

            }

            .theory-question-time {
                font-family: "Poppins";
                font-size: 15px;
                font-weight: 700;
                line-height: 15px;
                letter-spacing: 0.025em;
                text-align: left;
                margin: 5px;
                color: #0d3270;
            }
        }
    }

    .theory-question-body {

        .theory-question-container {
            // padding: 19px;
            width: 100%;
            // border-radius: 8px;
            border: 0.5px solid #C7C7C7;
            // margin-bottom: 10px;

            .tq-container-header {
                border-bottom: 1px solid #C7C7C7;
                padding: 10px;
                display: flex;
                color: #374671;

                .question-text {
                    font-family: "Poppins";
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.025em;
                    text-align: left;
                    margin-left: 5px;
                    line-break: anywhere;
                    width: 90%;
                }

                .question-statement{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .read-more{
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    width:800px;
                    padding: 2px;
                    align-self: flex-end;
                    text-align: start;
                }

                .view-code{
                    width: 100px;
                    height: 31px;
                    background:#011C67 !important;
                    border-radius: 6px;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 12px;
                    letter-spacing: 0.025em;
                    color: #FEFEFE !important;
                }

               
            }

            .tq-container-body {
                padding: 12px;

                .tq-container-topbar {
                    display: flex;
                    margin-bottom: 14px;


                    .question-difficulty-tag {
                        height: 23px;
                        width: fit-content;
                        padding: 4px 8px 2px 8px;
                        border-radius: 6px;

                        .question-difficulty-text {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 14px;
                            letter-spacing: 0.025em;
                            text-align: left;
                            color: #FFFFFF;
                        }

                    }

                    .star-rating {
                        margin-left: 24px;
                        display: flex;
                        justify-content: space-evenly;

                    }

                    .rating {
                        font-family: "Poppins";
                        margin-left: 4px;

                    }

                    .question-remark {
                        border: 1px solid black;
                        border-radius: 16px;
                        margin-left: 10px;
                        padding: 3px 8px 3px 8px;

                        .dot-icon {
                            color: #3D3D3D;
                            height: 10px;
                            width: 10px;
                            margin-right: 5px;
                        }
    
                        .question-remark-text {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 17px;
                            letter-spacing: -0.005em;
                            text-align: left;

                        }
                    }
                }

                .tq-body-tables {
                    height: max-content;
                    width: 100%;
                    margin: 2px;
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;


                    .tq-table-header {
                        height: 41px;
                        background-color: #F5F8FF;
                        padding: 12px;
                        width: 370px;
                        margin-bottom: 7px;

                        .table-header-text {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 14px;
                            letter-spacing: 0.025em;
                            text-align: left;
                            color: #374671;


                        }
                    }

                    .tq-table-body {
                        .table-body-title {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0.025em;
                            text-align: left;
                            color: #3D3D3D;
                            margin-bottom: 9px;
                            margin-left: 5px;

                        }

                        .table-body-description {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0.025em;
                            text-align: left;
                            color: #3D3D3D;
                        }

                        .dot-icon {
                            color: #3D3D3D;
                            height: 10px;
                            width: 10px;
                            margin-top: 3px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .coding-question-body 
    {
        .box-container
        {
            border: 0.5px solid #C7C7C7;

            .box-header
            {
                border-bottom:1px solid #CDCDCD;
                padding: 0px 16px;

                .question-statement{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .read-more{
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 500;
                    width: 500px;
                    padding: 2px;
                    align-self: flex-end;
                    text-align: start;
                }
            
                .view-code{
                    width: 100px;
                    height: 31px;
                    background:#011C67 !important;
                    border-radius: 6px;
                    font-family: 'Poppins';
                    font-weight: 600;
                    font-size: 12px;
                    letter-spacing: 0.025em;
                    color: #FEFEFE !important;
                }
            }
            .box-header > div:nth-child(1) {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.055em;
                color: #3D3D3D;
                white-space: break-spaces;
            }
            .box-header > div:nth-child(2)
            {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #374671;
                white-space: break-spaces;
            }
            .link p
            {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.055em;
                color: #3D3D3D;
                text-decoration: underline;
                margin-bottom: 0.2rem;
            }
            .link p:hover{
                color:#0d3270;
                text-decoration: underline;
            }
            .box-body
            {
                padding: 8px 16px;


                .candidateResponse{
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14px;
                    letter-spacing: 0.025em;
                    text-align: left;
                    color:#374671;
                }
            }
            
            .extra-comment > div:nth-child(1)
            {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #374671;
            }
            .extra-comment > div:nth-child(2)
            {
                font-family: 'Poppins';
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #3D3D3D;
            }
            .text-1
            {
                font-family: 'Poppins';
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #374671;
            }
            .bold-500
            {
                font-weight: 500;
            }
            .bold-600
            {
                font-weight: 600;
            }
            .bold-700
            {
                font-weight: 700;
            }
            .chip
            {
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #FEFEFE !important;
                border-radius: 6px;
                padding: 2px 6px;
            }
            .green
            {
                background: #3EAD2C;
            }
            .black
            {
                background: #3D3D3D;
            }
            .red
            {
                background: #B62C2C;
            }

            .design-heading{
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                color: #0D3270;
                margin-bottom: 5px;
                margin-top: 10px;

            }

            .design-label{
                font-family: 'Poppins';
                font-weight: 600;
                font-size: 12px;
                line-height: 117.4%;
                letter-spacing: 0.025em;
                color: #3D3D3D;
                width: 20%;
                min-width: 20%;
                margin-bottom: 5px;
            }

            .design-value{
                font-family: 'Poppins';
                font-weight: 500;
                font-size: 12px;
                line-height: 122.4%;
                letter-spacing: 0.025em;
                color: #3D3D3D;
                white-space: pre-wrap;
            }

        }
    }

    .quill-box {
        h1,h2,h3,h4 {
            font-size: 14px;
        }
        img{
            max-height: 400px;
            max-width: 60%;  
            margin: 6px;
            display: block;
        }
        ul{
            margin-left: 30px;
            margin-bottom: 5px;
        }
        ol{
            margin-left: 21px;
            margin-bottom: 5px;
            li{
               padding-left: 10px;
            }
        }
        p{
            line-break: auto;
            word-break: break-word;
            margin-bottom: 0.2rem;
        }
    }
}

.codebox-main-container
{
   .title
   {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 0.055em;
        text-transform: uppercase;
        color: #011c67;
   }
   .sub-title
   {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.035em;
    color: #3d3d3d;
   }
   .code
   {
    width: 100%;
    max-height: 600px;
    min-height: fit-content;
    background: black;
    color:white;
    border-radius: 4px;
    overflow-y: scroll;
   }
   .code-inner-box
   {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color:white;
    width: 400px;
    height: 600px;
    font-style: italic;
    white-space: pre-wrap;
   }
}

.custom-common-table
{
    width: 100%;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.025em;
    color: #3D3D3D;

            th
            {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #374671;
            }
            td
            {
                font-family: 'Poppins';
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #3D3D3D;
            }
}
.feedback-resume-section
{
    .link
    {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.025em;
        text-decoration-line: underline;
        color: #3D3D3D;
    }
    .rating-box >div:nth-child(1)
    {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.025em;
        color: #374671;
    }
    .extra-comment > div:nth-child(1)
            {
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #374671;
            }
            .extra-comment > div:nth-child(2)
            {
                font-family: 'Poppins';
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.025em;
                color: #3D3D3D;
            }
}