.multiple-slot-wrapper{
    margin: 30px 0;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    box-shadow: 0px 1px 12px rgba(11, 32, 94, 0.12);
    border-radius: 4px;
    padding: 20px;
    margin-right: 20px;

    .heading{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 13px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
        color: #555555;
    }
    .client-name{
        margin-top: 8px;
        margin-bottom: 25px;
    }
    .accept-btn{
        width: 50%;
        height: 40px;
        background: #123FBC;
        border-radius: 4px;
    }
}