@mixin textStyle($fontSize, $fontWeight, $lineHeight, $letterSpacing, $color) {
    font-family: Poppins;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
    color: $color
}

.sort-modal-wrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    background-color: #FFFFFF;
    padding: 0 16px 32px 16px;

    .sort-modal-header{
        height: 64px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D5E5FA;
        width: 100vw;
        margin-left: -16px;

        .header-text{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
            margin-left: 24px;
        }
    }
    .sort-options {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        margin-top: 16px;

        .sort-filter-button{
            @include textStyle(14px, 500, 18px, 0em, #3D3D3D);
            text-transform: none;
            width: fit-content;
        }
    }
}
.filter-modal-wrapper{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .filter-modal-header{
        height: 56px;
        padding: 20px;
        border-bottom: 1px solid #D5E5FA;
        .header-text{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
        }
        .clearFilter-btn{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
            text-transform: none;
            color: #123FBC;
        }
    }
    .filter-modal-content{
        height: calc(100% - 100px);
        .filters-list{
            background-color: #EEEEEE;
            padding-top: 24px;
            width: 156px;

            .filter-label{
                @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
                padding: 16px;
                cursor: pointer;
            }
            .selected-filter{
                background-color: #FFFFFF;
            }
        }
        .filter-options{
            margin-left: 8px;
            padding-top: 32px;

            .filter-option{
                .option-value{
                    @include textStyle(14px, 400, 18px, 0em, #3D3D3D);
                }
                .option-value.checked {
                    color: #123FBC;
                }
            }
        }
    }
    .filter-modal-footer{
        height: 48px;
        width: 100%;
        .close-button{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
            border-radius: 0;
            flex: 1;
            text-transform: none;
        }
        .apply-button{
            @include textStyle(14px, 600, 18px, 0em, #FFFFFF);
            background-color: #123FBC;
            box-shadow: none;
            border-radius: 0;
            flex: 1;
            text-transform: none;
        }
    }
}

/* Main (bottom) AppBar (Sort, Filter) */
.filters-footer {
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    background-color: #ffffff;
    box-shadow: 0px -1px 18px 0px #00000026;
    margin-left: 0 !important;

    & > * {
        flex-basis: 0;
        flex-grow: 1;
    }

    // AppBar buttons
    .sort-button,
    .filter-button {
        border: 1px solid #a5b2d7;
        border-radius: 0;

        .MuiButton-label {
            display: block;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            gap: 8px;
            text-transform: none;
            font-family: 'Poppins';
        }
    }
}

/* Date filter and Urgent filter */
.date-and-urgent-filters {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 16px;

    // Date filters wrapper
    .date-filters {
        display: flex;
        align-items: center;
        gap: 16px;
        width: fit-content;

        // React-Date-Picker
        .react-datepicker-wrapper {
            //max-width: 120px;
            width: clamp(56px, calc(56px + 8vw), 120px);
            height: 32px;

            & * {
                width: 100%;
                height: 100%;
            }

            & input {
                border: 1px solid #C9C9C9;
                border-radius: 4px;
                padding: 16px;
            }

            @media (min-width: 480px){
                width: clamp(56px, calc(56px + 24vw), 156px);
            }
        }
    }

    // Urgent checkbox
    .urgent-wrapper {
        .MuiTypography-body1 { // Label text
            font-family: 'Poppins';
            color: #3D3D3D;
            font-weight: 400;
            font-size: 16px;
        }
    }
}