.file-box{
    .img-container{
        margin-top: 14px;
        display: flex;
        gap: 16px;
        align-items: center;
    
        .upload-box{
            padding: 10px 20px;
            border: 1px solid #123FBC;
            cursor: pointer;
            border-radius: 2px;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.025em;
            color: #123FBC;
            display:flex;
            .c-red{
                color: #E10D0D;
                font-weight: 700;
            }
        }
        .remove-text{
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.045em;
            color: #123FBC;
            cursor: pointer;
        }
    }
    .instruction{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.025em;
        margin-top: 5px;
        color: #3D3D3D;
    }
}

.bank-details{
        .MuiInputLabel-root:after{
             content: " *";
             color: #E10D0D;
             font-weight: 600;
         }
         .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
             display: none;
         }
    .error-msg{
       color: #E10D0D;
       font-size: 11px;
       margin-top: -8px;
       font-weight: 400;
    }

    .info-container{
        width: fit-content;
        height: 25px;
        background: #D5E5FA;
        border-radius: 4px;
        padding: 2px 6px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.045em;
        color: #000000;
    }
    .td-uline{
        text-decoration: underline;
    }
}
