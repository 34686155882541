// Sidebar
.drawer-sidebar {
    // Paper in Drawer
    z-index: 2;
    .sidebar-paper {
        width: inherit;
    }
    .client-logo-img {
        width: 180px;
        height: 60px;
        object-fit: contain;
    }
    .iv-logo-img {
        width: 200px;
        height: 55px; 
        margin-left: 20px;
    }
}

// Modal
.leave-modal{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: 150px;
    background-color: #FFFFFF;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .modal-description{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.025em;
        text-align: center;
    }
    .modal-footer-btns{
        display: flex;
        align-items: center;
        .leave-btn{
            color: #123FBC;
            margin-right: 10px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.025em;
            height: 36px;
        }
        .stay-btn{
            background-color: #123FBC;
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.025em;
            height: 36px;
        }
    }
}

.sidebody-wrapper{
  width:100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding:12px 24px;
  gap: 30px;
}

.img-root-42 {
    width: 30px;
    height: 30px;
    margin-left: 60px !important;
    object-fit: cover;
    border-radius: 50%;
}