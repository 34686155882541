.loader-wrapper{
    background: rgba(26, 26, 26, 0.74);
    height: 100vh;
    width: 100vw;
    z-index: 99999999999999;
    position: fixed;
    inset: 0;
    gap: 8px;
}

.default-loader-text {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}