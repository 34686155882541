.overall-recommendation-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #3d3d3d;
}

.overall-recommendation-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #3d3d3d;
}

.comment-count-style {
  background-position-x: center !important;
  margin-top: -28px;
  margin-right: 10px;
  width: 46px;
  height: 29px;
  color: white;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.add-comment-feedback {
  min-width: 50px;
  padding: 0.5em;
}

#add-comment-parent .add-comment-feedback {
  visibility: hidden;
}

#add-comment-parent:hover .add-comment-feedback {
  visibility: visible;
}

.questionPrev {
  display: flex;
  flex-direction: row;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.rating-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3d3d3d;
}

.opacity-65 {
  opacity: 0.65;
}

.questionPrev {
  display: flex;
  flex-direction: row;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.strongly-proceed-color {
  color: #0F6908;
}

.colorize-proceed {
  -webkit-filter: hue-rotate(40deg) saturate(0.5) brightness(390%) saturate(4); 
 filter: hue-rotate(40deg) saturate(0.5) brightness(390%) saturate(4); 
}

.proceed-color {
  color: #71A41F;
}

.reject-color {
  color: #DA6C1D;
}

.strongly-reject-color {
  color: #E23939;
}

.preview-subheader-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0d3270;
}

.preview-subheader-subtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #374671;
}

.theory-questions {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.055em;
  color: #3d3d3d;
}

.theory-questions-unformatted {
  line-break: anywhere;
  font-family: 'Poppins';
  font-size: 16px;
  letter-spacing: 0.055em;
  color: #3d3d3d;
}

.theory-answers {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132%;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  margin: 20px;
}

.coding-questions-questions {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #374671;
  padding: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-45 {
  margin-right: 45px;
}

.center-align {
  text-align: center;
}

.width-25 {
  width: 25%;
}

.p-10 {
  padding: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.extra-comments-text {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #374671;
}

.table-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  border-right: 0.2px solid #bcc7e6;
  color: #374671;
}

.table-header:last-child {
  border-right: 0.2px solid #fff;
}

.table-data {
  word-break: break-word;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 149%;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  white-space: pre-line;
  padding-left: 20px;
  text-indent: -11px;
  margin-bottom: 10px;
}

.vertical-align {
  vertical-align: top;
}

.rate-feedback {
  background-color: #F5F8FF;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3D3D3D;
  border-radius: 46px;
  height: 35px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  text-align: center;
}

.ratings-style {
  display: flex;
  height: 35px;
  width: 60px;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3D3D3D;
}

.resume-url {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  text-decoration-line: underline;
  color: #011c67;
  margin-bottom: 15px;
}

.feedback-summary-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #494949;
  margin-top: 30px;
  margin-bottom: 10px;
}

.priority-heading{
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.025em;
  text-align: left;
  color: #3D3D3D;
  width: fit-content; 

.high{
background: #003EE9;
color: #ffffff;
padding: 8px;
border-radius: 6px;
}

.medium{
background: #9EC5FF;
padding: 8px;
border-radius: 6px;
}

.low{
background: #9EC5FF;
padding: 8px;
border-radius: 6px;
}
}
.feedback-summary-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  white-space: pre-line;
  margin-bottom: 10px;
  word-break: break-word;
}

.feedback-summary-rating-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.025em;
  color: #3D3D3D;
  margin-top: 15px;
  width: min-content;
  max-width: 100%;
  overflow-wrap: break-word;
}
.width-max-cont{
  width: fit-content;
}
.mb-20 {
  margin-bottom: 20px;
}

.coding-hld-lld-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  letter-spacing: 0.025em;

  color: #374671;

  width: 15%;
}

.popup-content {
  background: rgb(255, 255, 255);
}

.popup-arrow {
  background: transparent;
}

[role='tooltip'].popup-content {
  width: 300px;
  max-height: 300px;
  overflow: scroll;
  scroll-behavior: smooth;
  box-shadow: rgba(0, 0, 0, 0.46) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.white-bg-button {
  width: 100px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #0D3270;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: center;
  letter-spacing: 0.025em;

  color: #0D3270;
}

.blue-bg-button {
  border: none;
  width: 100px;
  height: 30px;
  background: #0D3270;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: center;
  letter-spacing: 0.025em;

  color: #FFFFFF;
}

.flipkart-preview-heading {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #0D3270;
  margin: 20px 0px 10px 0px;
}

.feedback-eval-separator {
  border: 1px solid #CDCDCD;
  margin-left: 20px;
  margin-right: 20px;
}

.coding-solution {
  font-family: Poppins;
  font-weight: bold;
  font-size: 12px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  opacity: 0.78;
  white-space: nowrap;
  align-items: center;
  margin-right: 20px;
}

.coding-solution-true {
  color: #59A41F;
}

.coding-solution-false {
  color: #B44545;
}

.suspicion-cheating {
  background: #FDB7B7;
  border-radius: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.025em;
  color: #B22B2B;
  width: fit-content; 
  padding: 8px;
  margin: 10px 0px;
}

.interviewer-box {
  display: flex;
  align-items: center;
  background: #F0F3FF;
  border-radius: 12px;
  height: 34px;
  margin-top: 10px;

  .interviewer-text {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    line-height: 117.4%;
    margin-left: 10px;
  }

  .interviewer-type {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 13px;
    line-height: 117.4%;
  }

  .bulb-icon {
    background: #FFFFFF;
    border-radius: 8px;
    height: 26px;
    padding: 4px;
    margin-left: 10px;
  }

  .c-44598D {
    color: #44598D;
  }
}