#detailsContainer {
  padding: 25px;
  display: flex;
  width: 520px;
  height: 100vh;
  overflow-y: scroll;
  line-height: 22px;
  flex-direction: column;

  .label {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-transform: capitalize;
    color: #3D3D3D;
    width: 50%;
  }

  .value {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.025em;
    color: #3D3D3D;
    width: 50%;
  }


  .audit-detail-section {
    background: #E5EFFC;
    padding: 26px 29px;
    margin-bottom: 15px;

    .heading {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 15px;
      line-height: 117.4%;
      letter-spacing: 0.025em;
      text-transform: capitalize;
      color: #3D3D3D;
      margin-bottom: 20px;
    }

    .comments-for-auditor{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    
    .comment-section {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.025em;
      color: #480606;
    }

    .resume-optional-warning{
      font-size: 12px;
    }

    .audit-feedback-sidebar-btn {
      background: #123FBC;
      border-radius: 4px;
      width: 207px;
      height: 36px;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.025em;
      color: #FFFFFF;
      border: none;
      outline: none;
      cursor: pointer;
    }

    .audit-sidebar-link {
      color: blue;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .candidate-feedback-wrapper {
    background: #FFFFFF;
    border: 1px solid rgba(165, 178, 215, 0.49);
    padding: 27px 31px;
    margin-top: 10px;

    .heading {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 15px;
      line-height: 117.4%;
      letter-spacing: 0.025em;
      text-transform: capitalize;
      color: #3D3D3D;
      margin-bottom: 20px;
    }

    .audit-sidebar-link {
      color: blue;
      text-decoration: underline;
      font-weight: 500;
    }

  }

}
