.parentBoxImageReference{
    padding: 25px;

    .font400 {
        font-weight: 400;
    }

    .font600{
        font-weight: 600;
    }


    .lightGray{
        color: #ADADAD;
   }

   .darkGray{
       color: #5C5C5C;
   }

   .commonText{
    font-family: "Poppins";
    font-weight: 400;
    font-size: 13px;
   }

   .commonHeading{
        font-family: "Poppins";
        font-weight: 600;
        font-size: 14px;
   }

    .headerBox{
         display: flex;
         justify-content: space-between;

         .headerText{
             font-family: "Poppins";
             font-size: 16px;
             color: #3D3D3D;
         }

         .closeDialog{
             cursor: pointer;
         }
    }

    .imageBox{
        width: 471px;
        min-height: 230px;
        border-radius: 7px;
        margin-top: 2%;
        margin-left: 3%;

        .imageDimension{
            max-width: 471px;
            max-height: 230px;
        }
    }

    .detailBox{
         display: flex;
         margin-top: 2%;
         margin-bottom: 4%;
        .indepthText{
            font-family: "Poppins";
            font-weight: 400;
            font-size: 13px;
        }

        .difficultyText{
            margin-left: 1%;
            font-family: "Poppins";
            font-weight: 700;
            font-size: 13px;
            color:#2B8415;
        }

        .textRed{
            color: #B60000;
        }

        .textYellow {
            color:  #C9B861;
        }
    
    }

    .horizontalLine{
        border-top: 1px solid #E3E3E3;
        width: 110%;
        margin-right: -4%;
        margin-left: -4%;
    }


    .thingsToCheckBox{
         width: 100%;
         height: auto;
         padding: 10px;
         background-color: #EFF4FF;
         margin-top: 4%;
         border-radius: 14px; 

         .checkMargins{
            margin-left: -2%;
            margin-top: 1%;
         }

         .checkHeading{
            color: #3B4B77;
         }

        
    }

    .answersBox{
        .ansHeading{
            color: #3D3D3D;
            margin-top: 3%;
        }

        .ansMargin{
            margin-left: 1%;
        }
    }

    
}