@mixin textStyle($fontSize, $fontWeight, $lineHeight, $letterSpacing, $color) {
    font-family: Poppins;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
    color: $color
}

.sort-modal-wrapper{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    background-color: #FFFFFF;
    .sort-modal-header{
        .header-text{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
        }
        height: 50px;
        padding: 15px 20px;
    }
    .sort-modal-content{
        .sort-item{
            padding: 15px;
            border: 1px solid #D5E5FA;
            .sort-label{
                @include textStyle(14px, 700, 18px, 0em, #3D3D3D);
                padding: 6px 8px;
            }
            .sort-filter-button{
                @include textStyle(14px, 500, 18px, 0em, #3D3D3D);
                text-transform: none;                
            }
        }
    }
}
.filter-modal-wrapper{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .filter-modal-header{
        height: 50px;
        padding: 20px;
        border-bottom: 1px solid #D5E5FA;
        .header-text{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
        }
        .clearFilter-btn{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
            text-transform: none;
            color: #123FBC;
        }
    }
    .filter-modal-content{
        height: calc(100% - 100px);
        .filters-list{
            background-color: #EEEEEE;
            .filter-label{
                @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
                padding: 10px;
            }
            .selected-filter{
                background-color: #FFFFFF;
            }
        }
        .filter-options{
            .filter-option{
                .option-value{
                    @include textStyle(14px, 400, 18px, 0em, #3D3D3D);
                }
            }
        }
    }
    .filter-modal-footer{
        height: 50px;
        width: 100%;
        .close-button{
            @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
            border-radius: 0;
            flex: 1;
        }
        .apply-button{
            @include textStyle(14px, 600, 18px, 0em, #FFFFFF);
            background-color: #123FBC;
            box-shadow: none;
            border-radius: 0;
            flex: 1;
        }
    }
}
