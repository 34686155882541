.section-v2-container {
    margin: 20px 0px;
    background: #ffffff;
    box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
    padding: 30px;
    position: relative;

    overflow-y: scroll;

    .required-text {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.02em;
        color: #8E1919;
    }

    .heading {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #0D3270;
    }

    .sub-heading {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #848484;
    }

    .question-title {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: #707070;
        margin: 15px 0px 5px 0px;
    }

    .question-body {
        border: 1px solid #D1CECE;
        padding: 20px;
    }

    .add-more-question {
        background: #3D3D3D;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        width: fit-content;
        margin-top: 20px;
        padding: 10px;
    }

    .add-more-points {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: #0D3270;
        width: fit-content;
        margin-top: 20px;
        padding: 10px;
    }

    .add-more-question:hover {
        background: #3D3D3D;
        opacity: 0.8;
    }

    .listed-section {
        .heading {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.025em;
            color: #0D3270;
        }

    }
    .design-bg{
       background-color: #F3F6FF;
       padding: 15px;
       margin: 10px 0px 15px 0px;
    }
    .skill-button {
        .button {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            background: #3D3D3D;
        }

        .button:hover {
            background-color: #757575;
        }

        .post-text {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: #3D3D3D;
        }
    }
}