.strap-wrapper{
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px -4px rgba(11, 32, 94, 0.12);
    border-radius: 4px;
    border-left: 7px solid#123FBC;
    width: 100%;
    padding: 13px 22px 14px;
    margin-top: 5px;

    .time{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: #123FBC;
    }
    .position{
        font-family: Source Sans Pro;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.025em;
        color: #555555;
        margin-top: 7px;
    }
    .client-name{
        font-family: Source Sans Pro;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.025em;
        color: #555555;
        margin-top: 5px;
    }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
    background-color: transparent !important;
    box-shadow: none !important;
}