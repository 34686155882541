
.login-parent-wrapper {
    display: flex;
    flex-direction: row; 
    // background-color: green;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    @media (max-width: 786px) {
         flex-direction: column-reverse;
    }

    .imageSection {
        width: 50vw;
        height: 100vh;

        .bannerImage {
             height: 100%;
             width: 100%;
             object-fit: contain;
        }
        @media (max-width: 786px) {
           width: 100vw;
           height: 50vh;
       }
    }
    

    .formSectionWrapper {
        display: flex;
        width: 50vw;
        height: 100vh;
        justify-content: center;
        align-items: center;

        @media (max-width: 786px) {
           width: 100vw;
           height:50vh;
           justify-content: flex-start;
       }
           .formSectionParent {
            width: 60%;
            @media (max-width: 786px) {
               margin-left: 10%;
               width: 80%;
               height: 100%;
            }
                .ivLogo {
                    margin-bottom: 10%;
                    @media (max-width: 786px) {
                         margin-top: 5%;
                         margin-left: 10%;
                         width: 80%;
                    }
                }
                        .loginInterviewerText {
                            font-family: 'Poppins';
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 36px;
                            text-align: left;
                            color: #1B1B1B;
                            margin-bottom: 5%;
                        }
                        .passwordMargins {
                            margin-top: 3%;
                        }
                        .inputFieldBox {
                                    .textFieldLabel {
                                        font-family: 'Poppins';
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        text-align: left;
                                        color: #1B1B1B;
                                        margin-bottom: 1%;
                                    }

                                    .textFieldStyles {
                                        width: 100%;
                                         border: 1px solid #B3B3B3;
                                         border-radius: 12px;
                                         height: 48px;
                                        
                                         padding-left: 10px;

                                         .MuiInputBase-input {
                                              color: #495057;
                                            
                                         }

                                         .MuiInputBase-input::placeholder {
                                            font-family: 'Poppins';
                                            font-size: 16px;
                                            font-weight: 500;
                                            line-height: 24px;
                                            color: #B3B3B3;
                                         }


                                         @media (max-width: 786px) {
                                            width: 100%;
                                       }

                                    }

                                    .warningBorder {
                                         border: 1px solid red;
                                    }

                                    
                        }


                        .showPasswordBox {
                            margin-top: 3%;
                               .showPassword {
                                        font-family: 'Poppins';
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 21px;
                                        color: #1B1B1B;
                                        margin-left: 2%;
                               }
                        }

                        .captchaContainer {
                               width: 100%;
                               display: flex;
                                justify-content: center;
                                align-items: center;

                            @media (max-width: 380px) {
                                
                               align-items: center;
                               justify-content: center;
                       }

                            .captchaAlignment {
                                height: 80px;
                                margin-top: 3%;
                                max-width: 100%; /* Ensure the captcha doesn't overflow */
                                transform: scale(1); /* Scale down to fit smaller screens */
                                transform-origin: 0 0;
    
                                @media (max-width: 380px) {
                                        transform: scale(0.8); /* Further scale down for very small screens */   
                               }

                               @media (max-width: 300px) {
                                transform: scale(0.6); 
                                 }

                                 @media (max-width: 200px) {
                                
                                    transform: scale(0.4); 
                               }
                            }
                        }

                       

                        .sign-in-btn {
                            height: 48px;
                            width: 100%;
                            background-color: #1844AD;
                            border-radius: 12px;
                            text-transform: none;
                            margin-top: 4%;

                               
                            @media (max-width: 786px) {
                                width: 100%;
                           }
                        }

                        .forgot-password {
                            margin-top: 3%;
                            margin-left: 32%;
                            .linkText {
                                font-family: 'Poppins';
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;
                                 color: #001340;
                            }
                        }

           }
    }
}