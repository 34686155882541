#crossButton{
    position: absolute;
    right: 15px;
    top: 15px;
}
#drawerbox{
    display:flex;
    flex-direction:column;
     border:1px solid blue; 
     width:500px 
}