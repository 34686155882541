.parent {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow-y: auto;
    padding-bottom: 48px;

    // Bottom fixed menu
    .bottom-menu {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 48px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        & > * {
            flex-basis: 0;
            flex-grow: 1;
        }

        // Buttons
        .cancel-button,
        .accept-button {
            height: 100%;
            border-radius: 0;

            & .MuiButton-label {
                // Button text
                text-transform: none;
                color: #fff;
                font-family: "Poppins";
            }
        }

        // Cancel button
        .cancel-button {
            background-color: #ec2121;
        }

        // Accept button
        .accept-button {
            background-color: #123fbc;

            &:disabled {
                background-color: #9a9a9a;
            }
        }
    }

    // For each section
    .section {
        width: 100%;
        border-bottom: 1px solid #d5e5fa;
        padding: 16px 24px;

        & > * + * {
            margin-top: 8px;
        }

        .section-title {
            font-weight: 600;
            font-family: "Poppins";
            font-size: 14px;
        }

        .detail-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: row;
            gap: 8px;

            .detail-key {
                font-size: 14px;
            }

            .detail-value {
                font-size: 14px;
                text-align: end;
            }

            .detail-value.link {
                cursor: pointer;
                color: #366BFF;
                text-decoration: underline;
            }

            .detail-value.green {
                color: #12BC93;
            }
        }

        .timeslot-box {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 8px;
            align-items: center;

            & > * {
                width: fit-content;
            }

            & > label {
                margin-bottom: unset !important;
            }
        }
    }

    // First section
    .section.section-1 {
        & > * {
            font-family: "Poppins";
        }

        .company-name {
            font-weight: 600;
        }

        .position-name,
        .round-name,
        .resume {
            font-weight: 400;
        }

        .resume {
            color: #123fbc;
            cursor: pointer;

            img {
                margin-left: 8px;
            }
        }
    }
}
