.backend-validation-modal-container {
  .modal-heading {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CDCDCD;

    .modal-error {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 23px;
      letter-spacing: 0.055em;
      text-transform: uppercase;
      color: #B22B2B;
    }
  }

  .modal-ok-button {
    width: 249px;
    height: 54px;
    background: #3D3D3D;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-top: 40px;
    float: right;
    cursor: pointer;
    font-weight: bold;
    color: white;
    font-size: 1.2rem;
  }

  .modal-details {
    margin-top: 20px;

    .modal-section-heading {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: #3d3d3d;
      margin-bottom: 10px;
    }

    .modal-section-question {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: #5F5F5F;
      margin-bottom: 5px;
    }

    .modal-section-title {
      font-family: 'Poppins';
      font-size: 12px;
      color: #5F5F5F;
      margin-bottom: 3px;
      margin-right: 10px;
    }

    .modal-section-error {
      font-family: 'Poppins';
      font-size: 12px;
      color: red;
      margin-bottom: 3px;
    }



  }

}