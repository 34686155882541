.code-block-container {
    .heading {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 23px;
        letter-spacing: 0.055em;
        text-transform: uppercase;
        color: #0D3270;
    }

    .description {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
        margin-bottom: 10px;
    }

    .modal-ok-button {
        width: 249px;
        height: 54px;
        background: #0D3270;
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        color: #FFFFFF;
        border-radius: 0px;
        float: right;
        margin-top: 40px;
    }
}