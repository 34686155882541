.rejectGuideTitle {
            font-family: 'Poppins';
            font-size: 18px;
            font-weight: 600;
            line-height: 22.39px;
            letter-spacing: 0.005em;
             color: #3D3D3D;
     }
   
    .guideRejectionBody {
        .reasonText {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            line-height: 14.93px;
            letter-spacing: 0.005em;
             color: #3D3D3D;
        }
       .modalBtnBox {
         margin-left: 38%;
         margin-top: 8%;
         margin-bottom: 2%;
         .disableBtn {
           opacity: 0.5;
           pointer-events: none;
         }
         .commonBtn {
                text-transform: none;
                font-family: 'Poppins';
                font-size: 14px;
                font-weight: 600;
                line-height: 17.42px;
                letter-spacing: 0.005em;
                height: 40px;
                width: 170px;
         }

         .cancelBtn {
                background-color: #FFFFFF;
                border: 1px solid #123FBC;
                color:#123FBC;
         }

         .rejectBtn {
            background-color: #123FBC;
            color:#FFFFFF;
            margin-left: 3%;
         }
        
       }

       .inputTextField {
        margin-top: 2%;
        width: 581px;

        .MuiInputBase-root {
            min-height: 63px;
        }
       }
    }  
