#formSubmitted{
  display:flex;
  padding:20px;
  gap:2%;

  .boxSize{
    width:40%;
    margin-left: 5%;
    
    img{
        width:99%;
    }
  }

  .boxSize2{
      width:45%;
      padding-top: 20px;

      #submitFirstText{
          font-size:63px;
          font-weight:700;
          line-height:69px;
          text-align:right;
      }
      #submitSecondText{
         width:410px;
         height:50px;
         float: right;
         margin-top:30px;

         text-align:right;
         font-size:16px;
         line-height:26px;
      }
    }

    #closeButton{
      width:180px;
      font-size: 16px;
      margin-top: 50px;
      float:right;
      border-radius: 20px;
      margin-left: 240px;
    }
}