@mixin textStyle($fontSize, $fontWeight, $lineHeight, $letterSpacing, $color) {
    font-family: Poppins;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
    color: $color
}
.AuditComp{

    .fl-col{
        display: flex;
        flex-direction: column;
    }
    .txt-align-right{
        text-align: right;
    }
    .mr-10{
        margin-right: 10px;
    }
    .mr-30{
        margin-right: 30px;
    }

    .headerComp{
        .heading{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
        .sub-heading{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
    }
    .p-btm{
        padding-bottom: 21px;
    }
    .pos-rel{
        position: relative;
    }
    .date-pickeroWN{
        width: 149px;
        height: 30px;
        border: 1px solid #9A9A9A;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 14px;
    }
    .dateIcon{
        position: absolute;
        top: 2px;
        right: 5px;
    }
    .bttn{
        width: 131px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #123FBC;
        box-sizing: border-box;
        border-radius: 4px;
        color: #123FBC;
    }

    .sideBorder{
        height:30px;
        border-right: 2px solid #C9C9C9;

    }
    .historyTable{
        tbody{
            tr:nth-child(even) {background-color: #F5F5F9;}
            tr:nth-child(odd) {background-color: #fff;}
            tr{
                &:hover{
                    box-shadow: 0px 7px 9px -5px rgba(31, 34, 43, 0.2);
                    transform: scale(1);
                }
            }
            td:first-child {
                border-top-left-radius: 4px; 
                border-bottom-left-radius: 4px;
            }
            td:last-child {
                border-bottom-right-radius: 4px; 
                border-top-right-radius: 4px; 
            }
        }
        .tableHeadingCol{
            background-color: #E6EFFC;
            text-transform: capitalize;
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.025em;
            color: #3D3D3D;
        }
        .table-content{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 117.4%;
            letter-spacing: 0.025em;
            color: #3D3D3D;
                
        }
        .table-hover tbody tr:hover{
            background-color: #FFFFFF !important;
        }
    }
    .tittle{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.025em;
        text-transform: capitalize;
        color: #3D3D3D;
    }
    .nothing-display{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
        color: #9A9A9A !important;

    }
    .table-content{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
        color: #3D3D3D;

    }

}
.toolbar-mobile{
    .header-section{
        padding: 15px;
        .page-title{
            @include textStyle(16px, 600, 20px, 0em, #3D3D3D);
        }
        .restore-button{
            @include textStyle(13px, 600, 15px, 0.025em, #123FBC);
            text-transform: none;
        }
    }
    .date-filters{
        padding: 0 15px;
        .date-pickeroWN{
            width: 130px;
        }
    }
}
.filters-footer{
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    margin-left: -12px;
    background-color: #FFFFFF;
    border: 1px solid #A5B2D7;
    .filter-button{
        @include textStyle(14px, 600, 18px, 0em, #3D3D3D);
        border-radius: 0;
        flex: 1;
        // height: 42px;
    }
    .left-button{
        border-right: 1px solid #A5B2D7;
    }
    .filter-icon{
        margin-right: 5px;
    }
}
.pagination-wrapper{
    margin-top: 1rem;
    margin-bottom: 60px;
}