.karma-box {
   min-width: fit-content;
   display: flex;
   flex-direction: column;
   align-items: center;

    .multi-graph {
        width: 160px;
        height: 80px;
        position: relative;
        font-size: 22px;
        font-weight: 600;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        box-sizing : border-box;
        z-index:5;
        margin-top: 5px;

        &:before{
        content: '';
        width: 160px;
        height: 80px;
        border:8px solid #D6D6D6;
        border-bottom: none;
        position: absolute;
        box-sizing : border-box;
        transform-origin: 50%  0%;
        border-radius: 300px 300px 0 0 ;
        left: 0;
        top: 0;
        }

        .graph {
            width: 160px;
            height: 80px;
            border:8px solid var(--fill);
            border-top: none;
            position: absolute;
            transform-origin :50%  0% 0;
            border-radius: 0 0 300px 300px ;
            left: 0;
            top: 100%;
            z-index: 5;
            animation : 1s fillGraphAnimation ease-in;
            transform: rotate( calc( 1deg * ( var(--percentage) * 1.8 ) ) );
            box-sizing : border-box;
            cursor: pointer;

            &:after{
                counter-reset: varible var(--percentage);
                background: var(--fill) ;
                box-sizing : border-box;
                border-radius : 2px;
                color: #fff;
                font-weight: 200;
                font-size: 12px;
                height: 20px;
                padding: 3px 5px;
                top: 0px;
                position: absolute;
                left: 0;
                transform: rotate(calc( -1deg * var(--percentage) * 1.8 ))  translate(-30px , 0px);
                transition:0.2s ease-in;
                transform-origin: 0 50% 0;
                opacity: 0;
            }
            &:hover{
                opacity: 0.8;
                &:after{
                opacity: 1;
                left: 30px;
                }
            }
            
            .thumb {
                height: 12px;
                width: 12px;
                background: white;
                border: 1px solid black;
                border-radius: 50%;
                margin-top: -9px;
                margin-left: -9px;
            }

            .no-display {
                display: none;
            }

            .red-thumb {
                border-color: #e10d0d;
            }
            .yellow-thumb {
                border-color: #ffb800;
            }
            .green-thumb {
                border-color: #00a642;
            }
            .dark-green-thumb {
                border-color: #008435;
            }   
        }

        .fw-700 {
            font-weight: 700;
        }
        .fs-30 {
            font-size: 30px;
        }
    }

    .red-text {
        color: #e10d0d;
        font-weight: 500;
    }
    .yellow-text {
        color: #ffb800;
        font-weight: 500;
    }
    .green-text {
        color: #00a642;
        font-weight: 500;
    }
    .dark-green-text {
        color: #008435;
        font-weight: 500;
    }

    .karma-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 127.5%;
        letter-spacing: 0.025em;
        color: #1B1B1B;
        margin-top: 15px;    
    }
  
  @keyframes fillAnimation{
    0%{transform : rotate(-45deg);}
    50%{transform: rotate(135deg);}
  }
  
  @keyframes fillGraphAnimation {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(180deg);}
  }

} 