.sidebar{
  width: 260px;
  margin-top: 20px;
}
.sidebar_top{
  width: 257px;
  border: 1px solid white;
  background: #FFFFFF;
  box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
  border-radius: 22px;

  .top_img{
    width:230px;
    margin: 8px 12px 8px 12px;
    height:85px;
    border-radius:15px;
    object-fit: contain;
  }

  .sidebar_box{
    width:224px;
    margin-left: 15px;
    margin-bottom: 15px;
    border-radius: 14px;
    background-color: #F3F5FA;
    font-size:12px;
    padding: 20px;

      .sidebar_heading{
        font-weight:700;
        font-size:13px;
        margin-top: 10px;
        color:#0F2359
      }
      .sidebar_info{
        font-weight:300;
        font-size:17px;
        color:#52659B;
        margin-top: -7px;

          .fontbold{
            font-size: 24px;
            font-weight: 800;
          }
      }
      .sidebar_result{
        color:white;
        height: 22px;
        font-weight: 700;
        font-size: 14px;
        width: 145px;
        margin-top: 6px;
        margin-bottom: 10px;
        text-align: center;
        border-radius: 7px;
        padding-top: 1px;
      }
  }
}
.sidebar_bottom{
  margin-top: 10px;
  margin-left: 10px;

  .headlink{
    font-size:15px;
    font-weight:600;
  }
  .headlink2{
   font-size:14px;
    height:12px;
    color:#A2A2A2;
  }
  .fontnormal{
    font-weight: 400;
  }

  .link_box{
    display:flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;

    .audit_links{
      height: 35px;
      display:flex;
      background-color: #C6D6FC;
      padding: 5px;
      border-radius: 14px;
      color:#0F2359;

      a:hover{
          cursor: pointer;
      }

      .curdisable{
        cursor: not-allowed;
        color:grey;
      }
    }
  }

  .resume-optional-warning{
    font-size: 12px;
  }
}
