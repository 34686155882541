.resultPopup{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 584px;
    height: 345px;
    background: #FFFFFF;
    box-shadow: 2px 3px 14px -5px rgba(0, 0, 0, 0.17);
    border-radius: 14px;

    .resultHeading{
        border-bottom: 1px solid #E3E3E3;
        padding: 18px;
        display:flex;
        gap:10%;
        .headingText{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
        }
    }
    .resultBody{
        padding: 20px;
        .resultText{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12.5px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
        }
        .warningText{
            display: flex;
            gap:5px;
        }
        .resultRedText{
            margin-top: 18px;
            margin-bottom: 35px;
            font-family: 'Poppins';
            font-style: normal;
            font-size: 12px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #D51818;
        }
        .selectBox{
            margin-left: 20px;
            display: flex;
            gap:15px;
            margin-bottom: 40px;
            .buttonBox{
                font-size: 14px;
                cursor: pointer;
                font-family: 'Poppins';
                padding: 3px 15px;
                border-radius: 14px;
            }
            .grayBox{
                background: #E3E3E3;
            }
            .redBox{
                background: #DE9811;
                color:#FFFFFF;
            }
            .darkRedBox{
                background: #B60000;
                color:#FFFFFF
            }
            .greenBox{
                background-color: #84C51B;
                color:#FFFFFF;
            }
            .darkGreenBox{
                background-color: #0B8701;
                color:#FFFFFF
            }
        }
        .resultConfirm{
            float: right;
            width: 170px;
            height: 40px;
            background: #123FBC;
            border-radius: 11px;

            font-weight: 600;
            font-size: 14px;
            line-height: 124.4%;
            text-align: center;
            letter-spacing: 0.005em;
            color: #FFFFFF;
        }
        .blur{
            opacity: 0.4;
        }
    }
}