.TableComp{
    .table-heading{
        background: #E6EFFC;
    }
    tbody{
        tr:nth-child(even) {background-color: #F5F5F9;}
        tr:nth-child(odd) {background-color: #fff;}
        tr{
            &:hover{
                box-shadow: 0px 7px 9px -5px rgba(31, 34, 43, 0.2);
                transform: scale(1);
            }
        }
        td:first-child {
            border-top-left-radius: 4px; 
            border-bottom-left-radius: 4px;
        }
        td:last-child {
            border-bottom-right-radius: 4px; 
            border-top-right-radius: 4px; 
        }
    }
    .acceptBtn{
        border: none;
        background: #123FBC;
        border-radius: 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 11.5px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        padding: 5px 30px;
    }
    .sendFeedbackBtn{
        border: none;
        background: #123FBC;
        border-radius: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        padding: 5px 15px;
    }
    .tableHeadingCol{
        background-color: #E6EFFC;
        text-transform: capitalize;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }
    .mb-60{
        margin-bottom: 60px;
    }
    .not-available{
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.disabled_row{
  background: "#F5F5F9";
    box-shadow: 0px 7px 9px -5px rgba(31, 34, 43, 0.2);
    border-radius: "4px";
    cursor:pointer;
}
.notdisabled_row{
  background-color:"white";
  cursor:pointer;
}