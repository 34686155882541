.result-clarity-container {

    .result-display {
        display: flex;
        flex-direction: row;
        padding: 10px;
        .edit-result-clarity{
            margin-left: 1%;
            height: 15px;
            cursor: pointer;
        }
    }

    .width-10 {
        min-width: 10%;
    }

    .width-18 {
        min-width: 18%;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .select-rating {
        width: 87%;
    }

    .select-text {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 10px;
        line-height: 124.4%;
        width: 87%;
        color: #123FBC;
        padding-bottom: 3%;
    }

    .head-text {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        line-height: 124.4%;
        color: #8E8E8E;
    }

    .result-text {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
    }

    .green-color {
        color: #0B8701;
    }

    .lime-color {
        color: #84C51B;
    }

    .yellow-color {
        color: #DE9811;
    }

    .red-color {
        color: #B60000;
    }

    .black-color {
        color: #3D3D3D;
        min-width: 10%;
    }

    .description-label {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.005em;
        line-height: 124.4%;
        color: #6C6C6C;
    }

    .proceed-box {
        display: flex;
        flex-direction: row;
        background: #ECF9EE;
        padding: 15px;
    }

    .select-box {
        display: flex;
        flex-direction: row;
        padding: 15px;
    }

    .reject-box {
        display: flex;
        flex-direction: row;
        background: #FAEEEE;
        padding: 15px;
    }




}