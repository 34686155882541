.main-bg {
  padding-bottom: 20px;
  background-color: #ffffff;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
}

.main_title {
  font-size: 16px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 19px;
  letter-spacing: 2.5%;
}

.loader-text {
  position: absolute;
  top: 420px;
  left: 47%;
  color: #3D3D3D;
}

.header-style {
  background-color: #0d3270;
  height: 52px;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.header-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  align-self: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

.instructions-box {
  border-radius: 16px;
  margin: 12px;
  padding: 5px 12px;
  align-self: center;
  display: inline-flex;
  cursor: pointer;
}

.instructions-box-unclicked {
  border: 1px solid #6a81a7;
  box-sizing: border-box;
}

.instructions-box-clicked {
  background: #ffffff;
  box-shadow: 0px 2px 24px -3px rgba(0, 0, 0, 0.15);
}

.instruction-i-style {
  font-family: "TimesNewRoman";
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  margin-right: 8px;
}

.instruction-text-unclicked {
  color: #ffffff;
}

.instruction-text-clicked {
  color: #0d3270;
}

.instruction-text-style {
  font-family: "Poppins";
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
}

.instruction-i-bg-dialog {
  width: 26px;
  height: 26px;
  border: 1px solid #0d3270;
  border-radius: 26px;
  font-family: "TimesNewRoman";
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  align-self: center;
  color: #0d3270;
  margin-right: 10px;
}

.download-pdf-text-style {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
}

.dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #011c67;
}

.feedback-approveBtn{
  color: #3f51b5;
  padding: 6px 8px;
  background-color: #fff;
  border: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
}

.dialog-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.035em;
  color: #3d3d3d;
}

.instruction-i-dialog-bg {
  width: 20px;
  height: 20px;
  border: 1px solid #3d3d3d;
  border-radius: 20px;
  font-family: "TimesNewRoman";
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  align-self: center;
  color: #3d3d3d;
  margin: 3px;
}

.interviewer-response-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.405em;
  text-transform: uppercase;
  color: #0d3270;
}

.interviewer-response-hr {
  width: 500px;
  border: 1px solid #cdcdcd;
  margin-bottom: 20px;
}

.feedback-background {
  padding: 20px;
  width: 1250px;
  margin: 52px auto auto auto;
}

.clientButtons {
  margin-left: auto;
  display: flex;
}

.details-box-style {
  border: 1px solid #aeb6cd;
  box-sizing: border-box;
  filter: drop-shadow(1px 2px 25px rgba(18, 63, 188, 0.07));
  justify-content: center;
  padding: 20px;
}

.strong-proceed-bg {
  border: 0px;
  box-shadow: 1px 2px 28px -2px rgba(15, 105, 8, 0.3);
}

.proceed-bg {
  border: 0px;
  box-shadow: 1px 2px 28px -2px rgba(113, 164, 31, 0.3);
}

.reject-bg {
  border: 0px;
  box-shadow: 1px 2px 28px -2px rgba(218, 108, 29, 0.3);
}

.strong-reject-bg {
  border: 0px;
  box-shadow: 1px 2px 28px -2px rgba(226, 57, 57, 0.3);
}

.vertical {
  border: 1px solid #cdcdcd;
}

.row-style {
  display: flex;
  flex-wrap: wrap;
}

.details-box-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  text-align: start;
  color: #011c67;
}

.details-box-label-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  width: 50%;
  /* white-space: nowrap; */
  margin-bottom: 5px;
}

.details-value {
  font-weight: 400;
}

.feedback-form-bg {
  margin: 20px 0px;
  background: #ffffff;
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 30px;
  position: relative;
  overflow-y: scroll;
}

.mtb-20 {
  margin: 20px 0px;
}

.mrl-20 {
  margin: 0px 20px;
}

.mrl-30 {
  margin: 0px 30px;
}

.width-100 {
  width: 100%;
}

.width-33 {
  width: 33.33%;
}

.width-66 {
  width: 66.66%;
}

.width-75 {
  width: 75%;
}

.width-25 {
  width: 25%;
}

.vertical-line {
  border: 0.5px solid #cdcdcd;
  margin: 0px 0px 0px 20px;
}

.side-drawer-feedback.open {
  transform: translateX(0);
}

.side-drawer-feedback {
  height: 100%;
  background: white;
  position: fixed;
  top: 80px;
  right: 5px;
  width: 22%;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 200;
  /* box-shadow: -2px 0px 20px rgba(0, 0, 0, 0.07); */
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
  height: 1000px;
  margin-bottom: 50px;
  margin-top: 50px;

}

.margin-top-50 {
  margin-top: 50px;
}

.blur {
  opacity: 0.3;
}

.overflow-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.overflow-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.previous-button {
  width: 249px;
  height: 54px;
  padding: 15px;
  border: 1px solid #0d3270;
  cursor: pointer;
  background: #ffffff;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #0d3270;
}

.previous-button:hover {
  opacity: 0.7;
}

.next-button {
  width: 249px;
  height: 54px;
  padding: 15px;
  background: #0d3270;
  border: 0px;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #ffffff;
}

.next-button:hover {
  opacity: 0.9;
}

.blur-8 {
  opacity: 0.8;
}

.preview-text-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  height: 25px;
  letter-spacing: 0.145em;
  color: #0d3270;
  background-color: #ffffff;
  border-radius: 4px;
  align-self: center;
  justify-content: center;
  text-align: center;
  margin-left: 5px;
  padding: 5px;
}

.warning-error {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin: 10px 0px;
  letter-spacing: 0.025em;
  color: #d94c4c;
}

.stepper-margin {
  margin: 0px 0px 20px 0px;
}

.suggested-comments-section {
  margin: 0px 0px 20px 20px;
  width: 100%;
}

.suggested-changes-text {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;

  letter-spacing: 0.1em;

  color: #0d3270;
}

.suggested-changes-section {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;

  letter-spacing: 0.1em;

  color: #858585;
}

.resolved-bg {
  background-color: #ffffff;
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.unresolved-bg {
  background-color: #ffcfcf;
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.comment-reply-bg {
  background-color: #FADCAF;
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
}

.p-20 {
  padding: 20px;
}

.padding-comment-box {
  padding: 10px 15px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.df-fdr {
  display: flex;
  flex-direction: row;
}

.df-fdc {
  display: flex;
  flex-direction: column;
}

.border-p-left {
  border-left: 1px solid #e8e8e8;
  padding-left: 40px;
}

.logo-style {
  margin-right: 20px; 
  height: 60px;
}

.jc-sb {
  justify-content: space-between;
}

.angry-style {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.smile-style {
  margin-left: 10px; 
  width: 30px; 
  height: 30px;
}

.emoji-style {
  height: 50px;
  width: 50px;
  margin: 0 10px;
}

.feedback-rating-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 40px;
}

.rating-style {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 124.4%;
  text-align: right;
}

.rating-digit-style {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 124.4%;
  color: #1844AD;
}

.feedback-rating-bg {
  width: 184px;
  height: 64px;
  background: #F5F8FF;
  border-radius: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.side-drawer-date {
  font-family: Poppins;
  font-weight: 500;
  font-size: 10px;
  line-height: 117.4%;
  /* or 9px */
  letter-spacing: 0.025em;
  color: #937A7A;
}


.side-drawer-name{
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #3D3D3D;
  margin-top: 2px;
}

.side-drawer-profile{
  font-family: Poppins;
  font-weight: 500;
  font-size: 10px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #937A7A;
}

.side-drawer-heading {
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.4%;
  /* or 21px */
  letter-spacing: 0.025em;
  color: #3D3D3D;
  padding: 5px 20px;

  position: sticky;
  z-index: 2;
  top: 0px;
  background: white;
}

.side-drawer-bg {
  box-shadow: 2px 4px 26px -3px rgba(0, 0, 0, 0.18);
}

.side-drawer-comments {
  font-family: Poppins;
  font-size: 14px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  white-space : break-spaces;
}

.mark-as-style-resolved {
  text-decoration-line: underline;
  color: #451c1c;
}

.mark-as-style-unresolved {
  text-decoration-line: underline;
  color: #3d3d3d;
}

.side-drawer-show-hide-replies {
  font-family: Poppins;
  font-size: 12px;
  line-height: 117.4%;
  letter-spacing: 0.025em;
  color: #3d3d3d;
}

.no-comments-available {
  background-color: #ffffff;
  box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
  margin: 10px 0px 10px 0px;
  padding: 30px;
  text-align: center;
}

.comments-button-background {
  border: 1px solid #E4C7C7;
  width: 27px;
  height: 27px;
  box-sizing: border-box;
  border-radius: 4px;
}

.comments-button-background:hover {
  background: #D7B6B6;
}

.vertical-reply-divider {
  border: 1px solid #FFCFCF;
}

.add-comment-reply {
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.p-15 {
  padding: 15px;
}

.comment-popup-row-style {
  width: 100%;
  margin-top: 10px;
}

.m-5-10 {
  margin: 5px 10px;
}

.vertical-line-comment-margins {
  margin-left: 15px;
  margin-top: -5px;
}

.example-section {
  width: 28em;
  height: 23em;
  padding: 20px;
  overflow-y: scroll;
  border-radius: 6px;
  position: absolute;
  right: 10px;
  top: 30px;
}

.resumeIntention {
  background: #EDF2FF;
  border: 1px solid #0D3270;
}

.good-exp-resumeIntention {
  color: #0D3270;
  font-weight: bold;
}

.highlights {
  background: #F6FFF5;
  border: 1px solid #65D80B;
}

.lowlights {
  background: #FFF7F1;
  border: 1px solid #E48C15;
}

.scopeOfImprovement {
  background: #F5F8FF;
  border: 1px solid #C2D4F0;
}

.good-exp {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #0D3270;
}

.good-exp-highlights {
  color: #0B8701;
}

.good-exp-lowlights {
  color: #E48C15;
}

.good-exp-scopeOfImprovement {
  color: #011C67;
}

.common-exp-heading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #3D3D3D;
}

.common-txt {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #3D3D3D;
}


#inMin {
  margin: 10px;
}

#experience {
  margin-bottom: 5px;
}

.preApprove {
  max-width: 50%;
}

.postApprove {
  max-width: 100%;
}

.text-area-feedback-form {
  width: 444px;
  min-height: 308px;
  margin-right: 15px;
  padding: 10px;
}

.text-area-feedback-form-full-width {
  width: 100%;
  min-height: 208px;
  margin-right: 15px;
  padding: 10px;
}

.previewResult {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16.2905px;
  line-height: 123%;
  letter-spacing: 0.025em;
}

.dgreen {
  color: #167914
}

.green {
  color: #84C51B
}

.red {
  color: #F40707
}

.dred {
  color: #B60000
}

/* Feedback_UI.scss */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.heading {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.outer-box {
  margin-top: 30px;
  background: #FFFFFF;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative;
  top: 12px;
}

.feedback-detail-box {
  background: #FAFBFF;
}

.feedback-row {
  display: grid;
  grid-template-columns: 300px auto;
  margin-bottom: 15px;
  height: 20px;
  line-height: 35px;

}

.rating-section {
  display: flex;
  width: 200px;
}

.rating-section span {
  margin-left: 10px;
}

.feedback-title-heading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.005em;
}

.feedback-title-rating {
  font-family: 'Poppins';
  font-size: 12px;
  letter-spacing: 0.005em;
}

.detail-section {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.005em;
  color: #6C6C6C;
}

.warning-box {
  display: flex;
  align-items: center;
  height: 58px;
  background: #98bba6;
  border-radius: 11px;
  padding-left: 10px;
  color: #3D3D3D;
}

.warning-first-details {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.005em;
  color: #3D3D3D;
  margin-bottom: 0;
}

.warning-first-details span {
  font-weight: bold;
  color: #B60000;
}

.warning-second-details {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.005em;
  margin-bottom: 0;
  color: white;
}

.feedback-summary-warning-box {
  display: grid;
  grid-template-columns: 310px auto;
  margin-top: 45px;
}

.previewWarning {
  width: 610px;
  display: flex;
  gap: 8px;
  padding: 10px;
  height: 41px;
  text-align: center;
  background: #FFFBDA;
  border-radius: 11px;

  .previewImg {
    height: 18px;
    width: 14px;
  }

  .previewText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #3D3D3D;

    .dgreen {
      color: #0B8701;
    }

    .green {
      color: #84C51B;
    }

    .yellow {
      color: #DE9811;
    }

    .dred {
      color: #B60000;
    }
  }
}

.rating-dropdown {
  margin-left: 25px;
}

.rating-summary {
  font-weight: bold;
  margin-bottom: 0;
}

.shared-feedback-edit-confirm-dialog
{
  height: fit-content;
  width: 660px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 4px;
  .title
  {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.025em;
    color: #4C4C4C;
    text-transform: uppercase;
  }
  .danger-icon
  {
    color: #F1A243;
    font-size: 28px;
  }
  .text
  {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.025em;
    color: #3D3D3D;
  }
  .btn
  {
    width: 158px;
    height: 36px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.025em;
    text-transform: capitalize;
  }
  .cancel-btn,.cancel-btn:hover
  {

    color: #3D3D3D;
    background: #FFFFFF;
    border: 1px solid #3D3D3D;
  }
  .edit-btn,.edit-btn:hover
  {
    color: #ffffff;
    background: #3D3D3D;
  }

}