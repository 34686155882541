.question_card_preview {
    .font-style
    {
        font-family: 'Poppins';
        letter-spacing: 0.005em;
    }
    .question-link
    {
        font-size: 11px;
        font-weight: 600;
        color: #5B87FF;
        text-decoration: underline;
        cursor: pointer;
    }
    .quill-box {
        width: 90%;
        line-break: auto;
        word-break: break-word;
        display: block;
        img{
            max-height: 400px;
            max-width: 60%;  
            margin: 6px;
            display: block;
        }
        ul{
            margin-left: 30px;
            margin-bottom: 5px;
        }
        ol{
            margin-left: 21px;
            margin-bottom: 5px;
            li{
               padding-left: 10px;
            }
        }
        p{
            line-break: auto;
            word-break: break-word;
            margin-bottom: 0.2rem;
        }
    }
    .mb-2p{
        margin-bottom: 2px;
    }
    .ml-10{
        margin-left: 10px;
    }
    .mr-5p{
        margin-right: 5px;
    }
    .font-11 {
        font-size: 11px;
    }

    .font-13 {
        font-size: 13px;
    }

    .bold-400 {
        font-weight: 400;
    }

    .bold-500{
        font-weight: 500;
    }

    .bold-600 {
        font-weight: 600;
    }

    .bold-900 {
        font-weight: 900;
    }

    .fg-gold {
        color: #C9B861;
    }
    .fg-gray4 {
        color: #535353;
    }

    .fg-black {
        color: #3D3D3D;
    }

    .fg-red {
        color: #B60000;
    }

    .nmt-5 {
        margin-top: -5px;
    }

    .fg-green {
        color: #47AC45;

    }

    .td-none:hover {
        text-decoration: none;
    }

    .white-space{
        white-space: break-spaces;
    }

    .tag{
        margin-top: -13px;
    }

    .width-15{
        min-width: 15px;
    }

    .image-ref-button {
        font-family: Poppins;
        background-color: #a2a2a2;
        color: white;
        border-radius: 4px;
        width: fit-content;
        margin-left: 19px;
    }
}

// Guide image Modal CSS
.guide-image-modal {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    outline: none;
   

    .guide-image-nav {
        background: #525659;
        height: 50px;
        padding: 20px;

        .image-label {
            font-family: Poppins;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            margin-right: 17px;
        }

        .close-icon {
            width: 17.4px;
            height: 17.4px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}
