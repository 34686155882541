// Feedback Video Modal CSS
.feedback-video-modal {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    outline: none;

    .video-link-nav {
        background: #525659;
        height: 80px;
        padding: 25px 37px;

        .recording-label {
            font-family: Poppins;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            margin-left: 17px;
        }

        .close-icon {
            width: 17.4px;
            height: 17.4px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}

.archive-video-wrapper {
    background-color: #3a3a3a;
    padding: 40px;

    .archive-img {
        height: 137px;
        width: 137px;
        object-fit: contain;
    }

    .archive-heading {
        font-family: Poppins;
        font-weight: 600;
        font-size: 36px;
        line-height: 0%;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        margin: 60px 0 20px 0;
    }

    .archive-subtext {
        font-family: Poppins;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        margin-bottom: 62px;
    }

    .support-text {
        font-family: Poppins;
        font-weight: 300;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
    }

    .contact-us {
        font-family: Poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
    }

}
