// IFRAME OVERLAY
.iframe-parent-box {
    position: fixed;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    z-index: 9999;

    // Close button container
    .close-button-container {
        position: fixed;
        top: 12px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 10001;
        padding: 0 12px;

        .close-icon-button {
            background-color: #3c3c3c;

            .close-icon {
                fill: #ffffff;
            }
        }
    }

    // Iframe
    iframe {
        z-index: 10000;
        width: 100%;
        height: 100%;
    }
}


// LOADER
.loader-wrapper-box {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .status-message {
        font-family: 'Poppins';
        color: #454545;
        font-weight: 600;
    }
}

// ERROR
.error-wrapper-box {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .status-message {
        font-family: 'Poppins';
        color: #df3030;
        font-weight: 600;
    }
}