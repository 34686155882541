.modal-title {
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 126%;
    text-align: center !important;
    letter-spacing: 0.025em !important;
    color: #3D3D3D !important;
}
.modal-content{
    min-height:300px;
}
.modal-btn{
    border: 1px solid #123FBC !important;
    width: 118px;
    height: 36px;
    margin-left: 15px !important;
}
.slot-text{
    font-family: Poppins !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 126%;
    text-align: left ;
    letter-spacing: 0.025em !important;
    color: #3D3D3D !important;
}
.error-text{
    font-family: Poppins ;
    font-style: normal;
    font-weight: 500 ;
    font-size: 12px ;
    line-height: 126%;
    text-align: center ;
    letter-spacing: 0.025em ;
    color:red;
    margin:0;
}

.btn .MuiButton-label{
    text-transform: capitalize;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
}

.btn:hover{
    color: white;
}
.btnPrimary:hover{
    color: #123FBC ;
}

.modal-btn--disabled{
    background-color: #C6C6C6 !important;
    color:#FFFFFF !important;
}
.modal-btn--enabled{
    background-color: #123FBC !important;
    color:#FFFFFF !important;
}

