#popUpContainer {
    background-color: #f9fbfd;
    padding: 1vw 2vw;
    position: absolute;
    // width: 750px;
    // height: 440px;
    margin-left: 400px;
    // top: 113px;
    top: 10%;
    right: 20%;
    overflow-y: scroll;
  }
  #headerContainer {
    display: flex;
    justify-content: space-between;
    height: 3vw;
    width: 100%;
    align-items: center;
    border-bottom: 0.5px solid lightgray;
  }
  .jobDescriptionHeader {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  #jobDescriptionContentBox {
    padding: 0.5vw;
    margin-top: 2vw;
    overflow-y: scroll;
    height: auto;
  }
  #jobDescriptionContentBox::-webkit-scrollbar {
    width: 2vw;
  }
  .jobDescrtiptionContent {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }
  .jobDescrtiptionContentDetails {
    line-height: 0;
  }
