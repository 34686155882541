.interview-card-wrapper{
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px -4px rgba(11, 32, 94, 0.12);
    border-radius: 4px;
    padding: 22px;
    margin: 15px 0;
    position: relative;
    // width: 320px;
    // height: 90px;
    // margin-left: 10px;
    width: 100%;
   
    .interview-status-label{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #555555;
    }
    .interview-month{
        font-family: Source Sans light;
        font-size: 14px;
        line-height: 119%;
        text-align: left;
        letter-spacing: 0.025em;
        color: #555555;
        margin-top: 8px;
    }
    .interview-count{
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 38px;
        line-height: 126%;
        text-align: right;
        letter-spacing: 0.025em;
    }
    .color-green{
        color: #0FBB54;
    }
    .color-red{
        color: #E10D0D;
    }
    .color-blue{
        color: #123FBC;
    }
    .banner{
        position: absolute;
        right: 0;
        top: 20px;
    }
}