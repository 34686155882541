.dashboard-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard-container-left{
    flex-basis: calc(60% - 10px);
    height:600px;
}
.dashboard-container-left .requests-container{
    height: 600px;
}
.dashboard-container-right{
    flex-basis: calc(40% - 10px);
}
.dashboard-container-right .interviews-scheduled{
    height:400px;
}
.requests-title{
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 117.4% !important;
    letter-spacing: 0.025em !important;
    color: #0D3270;
    margin: 0 0 17px 5px !important;
}
.cards-container{
    margin: 15px 0;
}
.dashboard-card{
    flex-basis: 19%;
}
.dashboard-card-pass-percentage{
    flex-basis: 28%;
}
.dashboard-card-complete-cancel{
    flex-basis: 26.5%;
}

.height-cancel-complete{
    min-height: 100%;
}
.card-item{
    padding: 1rem;
    min-height:45%;
    box-shadow: 0px 1px 19px -4px rgba(11, 32, 94, 0.2) !important;
    border-radius: 4px !important;
}
.card-item .content{
    height:100%;
    width: 100%;
}
.theme-blue{
    color:#123FBC;
}
.theme-green{
    color:#48C87B;
}
.theme-red{
    color:#E80512;
}
.card-item .number{
    font-family: Poppins;
    font-weight: 600;
    font-size: 36px;
    line-height: 126%;
    letter-spacing: 0.025em;
}
.card-item .title{
    font-family: Poppins;
    font-weight: 550;
    font-size: 14px;
    line-height: 126%;
    letter-spacing: 0.025em;
    margin-bottom: 5px;
}
.card-item .sub-title{
    font-size: 13px;
    color: #858585;
}
.card-item .description{
    font-family: Poppins;
    font-size: 12px;
    line-height: 119%;
    letter-spacing: 0.025em;
    text-align: center;
}
.special-card{
    border-radius: 10px !important;
    width:300px;
    min-height:130px;
    padding: 1rem;  
    box-shadow: 0px 1px 19px -4px rgba(11, 32, 94, 0.2) !important;
    border-radius: 4px !important;
}
.special-card-body{
    width:100%;
    height:100%;
}
.special-card .title{
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.025em;
    color: black;
}
.special-card .img{
    width:90px;
    height:70px;
}
.special-card .content{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.025em;  
    color: #123FBC;  
}
.special-card .normal{
    font-family: Poppins;
    font-weight: 600;
    letter-spacing: 0.025em;  
    color: #123FBC;
    font-size: 15px;
    line-height: 22px;
}
.special-card .extra-info{
    font-family: Poppins;
    font-size: 8px;
    line-height: 120.5%;
    text-align: center;
    letter-spacing: 0.025em;    
    color: #4F4D4D;
}
.black-555555{
    color:#555555;
}
.mb-5{
    margin-bottom: 5px !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.width-85{
    width: 85% !important;
}
.availability-modal{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 500px;
    height: 160px;
    background-color: #FFFFFF;
    padding: 10px 25px 25px 25px;
}
.availability-modal .close-icon{
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}
.availability-modal .modal-description{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.025em;
}
.availability-modal .availabilityPage-link{
    background-color: #123FBC;
    color:#FFFFFF;
    text-transform: inherit;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.025em;
    height: 32px;
}
.passPercentage-comparator{
    height: 50px;
    background: #FEE2C8;
    border-radius: 4px;
    .pass-percent-infoIcon{
        margin-right: 8px;
    }
    .compare-text{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.025em;
        padding: 15px;
        color: #715858;
    }
    .triangle{
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom: 20px solid #FFE8D3;
        transform: translateX(30px) rotate(180deg);
    }
}
.passPercent-container{
    position: relative;
    width: 90%;
    height: 8px;
    border-radius: 4px;
    margin-top: 35px;
    .average-tracker{
        position: absolute;
        top: 0px;
        display: flex;
        flex-direction: column;
        height: 15px;
        .average-text{
            width: 120px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0.025em;
            margin-top: 5px;
            text-align: left;
            transform: translateX(-15px);
            span{
                color: #11DC62;
            }
        }
        .transform-left{
            text-align: right;
            transform: translateX(-90px);
        }
    }
    .passPercent-tracker{
        position: absolute;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        .passPercent-text{
            width: 80px;
            transform: translateX(-20px);
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0.025em;
            margin-bottom: 5px;
            .color-red{
                color: #E80512;
            }
            .color-yellow{
                color: #FFB800;
            }
            .color-green{
                color: #11DC62;
            }
        }
        .transform-left{
            transform: translateX(-40px);
        }
    }
    .indicator-icon{
        width: 10px;
        height:15px;
    }
}
.passPercent-yellow{
    border: 1px solid #FFB800;
}
.passPercent-red{
    border: 1px solid #E60B0B;
}
.pass-percent-full-view-wrapper{
    width: 20%;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 19px -4px rgba(11, 32, 94, 0.2);
    padding: 0 30px;
    border-radius: 4px;

    .percent-heading{
        font-family: Poppins;
        font-weight: 550;
        font-size: 14px;
        line-height: 126%;
        letter-spacing: 0.025em;
        margin-bottom: 5px;
        text-align: center;
        margin-bottom: 10px;
    }
}

// for Mobile View only

.mobile-view-card-wrapper{

    .pass-percent-wrapper{
        margin: 30px 0 0;
        background: rgba(237, 33, 33, 0.12);
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        box-shadow: 0px 2px 12px -4px rgba(11, 32, 94, 0.17);
        border-radius: 4px;
        padding: 15px 28px 40px;

        .pass-percent-text{
            font-family: Source Sans Pro;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: #E50B0B;
        }
        .width-60{
            width: 60%;
        }
        
    }
    .scroll-wrapper{
        max-width: 600px;
        overflow: scroll;
    }
}

.ratings{
    .ratings-count{
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #858585;
      }
      .ratings-bar{
        font-size: smaller !important;
      }
}

  .ratings-value{
    text-transform: uppercase;
    color: #123FBC;
    .size--big{
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0.025em;
    }
    .size--normal{
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.025em;
    }
  }
  .tat-feedback-box{
    color: #123FBC;
    .size--big{
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0.025em;
    }
  }