a{
  all:unset;
  cursor: pointer;
}
button{
  cursor: pointer;
}

.DetailedScheduled{
    .m-b-10{
        margin-bottom: 10px;
    }
    .txt-align-right{
        text-align: right;
    }
    .txt-capitalize{
        text-transform: capitalize;
    }
    .text-candidate-not-joined{
        font-family: Poppins;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }
    
    .subtext-candidate-not-joined{
        font-family: Poppins;
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }

    .text-client-notified{
        font-family: Poppins;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: #D77E15;
    }
}

.close-style {
  float: right;
  font-size: 1.40625rem;
  font-weight: 600;
  line-height: 1;
  color: #3D3D3D;
  text-shadow: none;
  cursor: pointer;
}

.close-style:hover {
  color: #95aac9;
  text-decoration: none
}

.btnConfirmSwal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #123FBC !important;
    background: #FFFFFF !important;
    border: 1px solid #123FBC !important;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 16px;
}
.btnCancelSwal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #FFFFFF !important;
    background: #EC2121 !important;
    border-radius: 2px;
    padding: 5px 16px;
}

.details-box{
  background-color: #E5EFFC;
  padding:30px;
  font-family: Poppins;
  .heading-client{
    font-size:15px;
    font-weight: 500;
  }
  .position-client-details{
    font-size: 13px;
    margin-bottom: 10px;
    .positon-client-details-box{
      margin-top:5px;
      font-weight: 500;
    }
  }
  .rounds-button-group button{
    width: 178px;
    height: 36px;
    border: 1px solid #123FBC;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
  }

  .resume-optional-warning{
    font-size: 12px;
  }
  .second-group{
    background-color: #123FBC;
    color:white;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.025rem;
    img{
      margin-right: 10px;
    }
  }
  .first-button{
    background: none;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.025rem;
    .Items-logo-link{
      margin-right: 10px;
    }
  }
  .interview-details{
    padding-left:15px;
    font-size:13px;
    .Interview_details_logo{
      margin-right: 10px;
    }
  }
  .history-feedback-box {
    box-shadow: -4px 5px 22px -10px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    width: fit-content;
    min-width: 160px;
    height: fit-content;

    .result {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      letter-spacing: 0.005em;
    }

    .ratings{
      font-weight: 600;
      font-size: 25px;
      line-height: 39px;
      text-align: right;
      color: #3D3D3D;
    }

    .report{
      background: #FEFEFE;
      border: 1px solid #cfcfcf;
      border-radius: 6px;
      height: fit-content;
      padding: 5px 10px;
      width: 100%;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-align: right;
      letter-spacing: -0.005em;
      color: #000000;

    }
  }
  .candidate-did-join{
    margin-top:20px;
    p:nth-child(1){
      font-weight: 600;
      font-size: 14px;
      span{
        color:#F38300;
        text-decoration: underline;
      }
      .c-pointer{
        cursor: pointer;
      }
    }
    p:nth-child(2){
      font-size: 12px;
    }
    .notify-text{
      margin-bottom: 0px;
    }
  }
  .candidate-join-not-allowed{
    cursor: not-allowed;
    opacity: 0.4;
  }
  .cancel-interview{
    margin-top:20px;
    width:95%;
    height: 36px;
    border: 1px solid #E80512;
    box-sizing: border-box;
    border-radius: 4px;
    color: #E80512;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 117.4%;
  }
}
.feedback-sidebar{
  margin-top:20px;
  padding:30px;
  min-height: 100px;
  background: #FFFFFF;
  border: 1px solid rgba(165, 178, 215, 0.49);
  box-sizing: border-box;
  .feedback-heading{
    font-weight: 500;
  }
  .feedback-button{
    cursor: pointer;
    height: 36px;
    background: #E5EFFC;
    border: 1px solid #123FBC;
    border-radius: 4px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.025em;
    color: #123FBC;
    width: 189px;
    height:36px;
    line-height: 33px;
    margin-top:10px;
  }
}
.comments-sidebar{
  background: #FFFFFF;
  border: 1px solid rgba(165, 178, 215, 0.49);
  box-sizing: border-box;
  min-height: 100px;
  padding:32px;
  margin-top: 20px;

  .comment-row{
    display: flex;
    justify-content: space-between;
  }

}
.round-history-sidebar{
  min-height: 80px;
  background: #FFFFFF;
  border: 1px solid rgba(165, 178, 215, 0.49);
  box-sizing: border-box;
  padding:33px;
  margin-top:20px;
  // overflow-y: scroll;

  .redirect-link{
    cursor: pointer;
    font-size: 13px;
    color: #366BFF;
    text-decoration: underline;
  }
}
.roundHistoryDesign{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .roundHistoryText{
    margin:0;
  }
  p:nth-child(1){
    font-weight: bold;
  }
  .first_col{
    p:nth-child(2){
      font-size:12px;
      color:grey;
    }
  }
}

.bg-strong-proceed{
  background: #cef1ca;
  border: 1px solid #75ac71;
}
.bg-proceed{
  background: #D3EFD0;
  border: 1px solid #A0CE9C;
}
.bg-strong-reject{
  background: #faecec;
  border: 1px solid #f8bfbf;
}
.bg-reject{
  background: #f7f3c8;
  border: 1px solid #e6da33;
}
.c-45720a{
  color: #45720a
}
.c-0F7C06{
  color: #0F7C06;
}
.c-DE9811{
  color: #DE9811;
}
.c-B83A14{
  color: #B83A14;
}
.c-grey{
  color: grey;
}
.fw-400{
  font-weight: 400;
}
.fw-600{
  font-weight: 600;
}
.fs-20{
  font-size: 20px;;
}
.fs-15{
  font-size: 15px;
}