.history-card-wrapper{
    padding: 20px 15px;
    .header-label{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.025em;
    }
    .header-value{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.025em;
    }
}
.card-odd{
    background-color: #f5f5f9;
}