.DetailedRequests{
    .m-b-10{
        margin-bottom: 10px;
    }
    .txt-align-right{
        text-align: right;
    }
    .txt-capitalize{
        text-transform: capitalize;
    }
    .resume-optional-warning{
        font-size: 12px;
      }
}

.btnConfirmSwal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #123FBC !important;
    background: #FFFFFF !important;
    border: 1px solid #123FBC !important;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 16px;
}
.btnCancelSwal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #FFFFFF !important;
    background: #EC2121 !important;
    border-radius: 2px;
    padding: 5px 16px;
}