.request{
   
    .fl-col{
        display: flex;
        flex-direction: column;
    }
    .txt-align-right{
        text-align: right;
    }
    .mr-10{
        margin-right: 10px;
    }
    .mr-30{
        margin-right: 30px;
    }

    .headerComp{
        .heading{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
        .sub-heading{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: #3D3D3D;
        }
    }
    .p-btm{
        padding-bottom: 21px;
    }
    .pos-rel{
        position: relative;
    }
    .date-pickeroWN{
        width: 149px;
        height: 30px;
        border: 1px solid #9A9A9A;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 14px;
    }
    .dateIcon{
        position: absolute;
        top: 2px;
        right: 5px;
    }
    .bttn{
        width: 131px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #123FBC;
        box-sizing: border-box;
        border-radius: 4px;
        color: #123FBC;
    }

    .sideBorder{
        height:30px;
        border-right: 2px solid #C9C9C9;

    }
    .tittle{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.025em;
        text-transform: capitalize;
        color: #3D3D3D;
    }
    .nothing-display{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
        color: #9A9A9A !important;

    }
    .table-content{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 117.4%;
        letter-spacing: 0.025em;
        color: #3D3D3D;

    }
    .urgnt-checkbox{
        border: 1px solid #C9C9C9 !important;
        box-sizing: border-box;
        border-radius: 4px;
    }
}