.comment-icon-main-container
{
    .blue-icon{
        color: #123FBC;
    }
    .yellow-icon{
        color: #F47723;
    }
}
.common-shared-comment-body
{   
    .text-1
    {
        font-family: 'Poppins';
        font-weight: 500;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        font-size: 14px;
    }
    .text-2
    {
        font-family: 'Poppins';
        font-weight: 400;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        font-size: 12px;
    }
    .MuiOutlinedInput-notchedOutline
    {
        outline: none;
        border: none;
    }
    .input
    {
        width: 100%;
        height: 32px;
        outline: none;
        border: none;
        border-radius: 4px;
        border: 1px solid #C9C9C9;
        display: flex;
        justify-content: center;
    }
    
    .input input::placeholder
    {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        color: #727272;
        letter-spacing: 0.025em;
        vertical-align: center;
    }
    .input-2
    {
        padding-right: 28px;
    }
    .tick-img
    {
        width: 20px;
        position: absolute;
        z-index: 3;
        right: 32px;
        margin-top: 6px;
    }
    .btn-group
    {
        width: 100%;
        height: fit-content;
        float: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
        gap:10px;
        .btn
        {
            width: 200px;
            height: 40px;
            border-radius: 11px;
        }
        .btn:active
        {
            box-shadow: none;
        }
        .btn-1
        {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.005em;
            color: #123FBC;
            border: 1px solid #123FBC;
            text-transform: capitalize;
        }
        .btn-2
        {
            background: #979696;
            color: #FFFFFF;
            letter-spacing: 0.005em;
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
    .email-error
    {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        color: #CF0808;
        letter-spacing: 0.025em;
    }
    
}